import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

const StyledIcon = styled.span`
    color: #485fc7;
    cursor: pointer;
    :hover {
        color: #363636;
    }
`

const ToolTipIcon = React.forwardRef<HTMLSpanElement>((_, ref) => (
  <StyledIcon ref={ref}>
    <FontAwesomeIcon icon={faQuestionCircle} />
  </StyledIcon>
))

export default ToolTipIcon
