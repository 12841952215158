import axios, { AxiosError, AxiosInstance } from 'axios'
import React, { useEffect, useMemo, useRef } from 'react'
import { dBaaSBaseEndpoint, taxonomyBaseEndPoint } from '../../config/api'
import useAccessToken from '../../hooks/auth/useAccessToken'
import { useAuthUpdate } from '../AuthProvider'
import DBaaSClientContext from './DBaaSClientContext'

function DBaaSClientProvider({ children }: { children: React.ReactNode }) {
  const updateAuth = useAuthUpdate()
  const updateAuthRef = useRef(updateAuth)
  const accessToken = useAccessToken()

  const clients = useMemo(() => {
    const dBaaSAxiosClient = axios.create({
      baseURL: dBaaSBaseEndpoint,
    })

    const taxonomyAxiosClient = axios.create({
      baseURL: taxonomyBaseEndPoint,
    })

    return { dBaaSClient: dBaaSAxiosClient, taxonomyClient: taxonomyAxiosClient }
  }, [])

  const { dBaaSClient, taxonomyClient } = clients

  useEffect(() => {
    const addRequestInterceptor = (axiosInstance: AxiosInstance) => (
      axiosInstance.interceptors.request.use((config) => {
        const newConfig = { ...config }
        if (config.headers != null && !('Authorization' in config.headers)) {
          const token = accessToken
          if (token != null && token?.length > 0) {
            newConfig.headers = { ...config.headers, Authorization: `Bearer ${token}` }
          }
        }
        return newConfig
      }))

    const addResponseInterceptor = (axiosInstance: AxiosInstance) => (
      axiosInstance.interceptors.response.use(
        (config) => config,
        (error: AxiosError) => {
          if (error.message === 'Network Error'
            || (error.config.url === '/isadmin' && error.response?.status !== undefined && error.response?.status >= 500)
            // experienced 404 error without this line. re-check if still needed
            || (error.config.url === '/isadmin' && error.response?.status === 404)) {
            // application should show global error modal
            updateAuthRef.current.updateAuthOneValue('hasError', true)
          }
          return Promise.reject(error)
        },
      ))

    const dBaaSRequestInterceptor = addRequestInterceptor(dBaaSClient)
    const dBaaSResponseInterceptor = addResponseInterceptor(dBaaSClient)

    const taxonomyRequestInterceptor = addRequestInterceptor(taxonomyClient)
    const taxonomyResponseInterceptor = addResponseInterceptor(taxonomyClient)

    return () => {
      /* cleanup */
      dBaaSClient.interceptors.request.eject(dBaaSRequestInterceptor)
      dBaaSClient.interceptors.response.eject(dBaaSResponseInterceptor)
      taxonomyClient.interceptors.request.eject(taxonomyRequestInterceptor)
      taxonomyClient.interceptors.response.eject(taxonomyResponseInterceptor)
    }
  }, [dBaaSClient, taxonomyClient, accessToken])

  return (
    <DBaaSClientContext.Provider value={clients}>
      {children}
    </DBaaSClientContext.Provider>
  )
}

export default DBaaSClientProvider
