import { useQuery } from 'react-query'
import { useDBaaSClient } from '../../client'
import IServer from '../../../types/IServer'
import { useFetchIsAdmin } from '../admin'

const useFetchServers = () => {
  const { get } = useDBaaSClient()

  const { isFetching: isLoading, isSuccess } = useFetchIsAdmin()
  return useQuery(['servers'], () => get<IServer[]>('/servers'), {
    enabled: !isLoading && isSuccess,
  })
}

export default useFetchServers
