import React, { useEffect, useState } from 'react'
import { useAuth } from '../../providers/AuthProvider'
import Spinner from '../Spinner/Spinner'

function AppSpinner({
  isLoading,
  children,
}: { isLoading: boolean, children: React.ReactNode }) {
  const [tip, setTip] = useState('')

  const { isLoading: isAuthLoading } = useAuth()
  const loading = isLoading || isAuthLoading

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null
    if (isAuthLoading) {
      timer = setTimeout(() => {
        setTip('Slow connection... are you connected to the VPN?')
      }, 20000)
    } else {
      setTip('')
    }

    return () => {
      if (timer != null) {
        clearTimeout(timer)
      }
    }
  }, [isAuthLoading])

  return (
    <Spinner tip={tip} isLoading={loading}>{children}</Spinner>
  )
}

export default AppSpinner
