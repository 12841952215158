import React, { useMemo } from 'react'
import classNames from 'classnames'
import BaseElements, { normalizeAlign } from '../../../elements'
import {
  IContainerElementProps,
} from '../../../elements/types'
import FieldContext from './Context'
import { Size } from './types'

const { Div: Element } = BaseElements

type FieldProps = Omit<IContainerElementProps, 'renderAs'> & {
  align?: 'center' | 'right';
  kind?: 'addons' | 'group';
  multiline?: boolean;
  horizontal?: boolean;
  size?: Size
}

const Field = React.forwardRef<HTMLDivElement, FieldProps>(({
  className,
  align,
  multiline,
  horizontal,
  kind,
  size,
  ...props
}, ref) => {
  let k = null

  if (kind === 'addons') {
    k = 'has-addons'
  } else if (kind === 'group') {
    k = 'is-grouped'
  }

  const value = useMemo(() => ({ size }), [size])

  return (
    <FieldContext.Provider value={value}>
      <Element
        {...props}
        className={classNames('field', className, {
          [`${k}`]: k,
          [`${k}-${normalizeAlign(align)}`]: k === 'is-grouped' && align,
          [`${k}-multiline`]: k === 'is-grouped' && multiline,
          'is-horizontal': horizontal,
        })}
        ref={ref}
      />
    </FieldContext.Provider>
  )
})

Field.defaultProps = {
  align: undefined,
  kind: undefined,
  multiline: undefined,
  horizontal: undefined,
  size: undefined,
}

export default Field
