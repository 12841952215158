import { useQuery } from 'react-query'
import { useDBaaSClient } from '../../client'
import { useFetchIsAdmin } from '../admin'

const useFetchDatacenter = () => {
  const { get } = useDBaaSClient()

  const { isFetching: isLoading, isSuccess } = useFetchIsAdmin()
  const canFetch = !isLoading && isSuccess

  return useQuery(['datacenters'], () => get<string[]>('/datacenters'), { enabled: canFetch })
}

export default useFetchDatacenter
