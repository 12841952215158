import InternalIcon from './icon'

type InternalIconType = typeof InternalIcon

interface IconType extends InternalIconType {
    Text: InternalIconType
}

const Icon = InternalIcon as IconType

Icon.Text = InternalIcon

export default Icon
