import { useMutation, useQueryClient } from 'react-query'
import { useDBaaSClient } from '../../client'

interface ICreateServerBody {
    host: string,
    description: string,
    datacenterName: string,
    name: string,
    capacity: number,
    state: boolean,
    databaseVersion: string,
    customConnectionString: string,
    user: string,
    password: string,
    passwordConfirmation: string,
    providerName: string,
    customClientHost: string,
    pgPoolServers: string,
    serviceNowId: string,
    ssl: boolean
}

const useCreateServer = () => {
  const { post } = useDBaaSClient()

  const queryClient = useQueryClient()

  return useMutation((body: ICreateServerBody) => post('/servers', body), {
    onSuccess: () => {
      queryClient.invalidateQueries('servers')
    },
  })
}

export default useCreateServer
