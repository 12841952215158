import classNames from 'classnames'
import React, { forwardRef } from 'react'
import { ContainerElement } from '../../elements'
import { IContainerElementProps } from '../../elements/types'

interface TagGroupProps {
    hasAddons?: boolean;
    renderAs?: Extract<IContainerElementProps['renderAs'], 'div' | 'span'>
}

type IProps = Omit<IContainerElementProps, 'innerRef'> & TagGroupProps

const TagGroup = forwardRef<HTMLDivElement | HTMLSpanElement, IProps>((props, ref) => {
  const {
    children, hasAddons, className, renderAs, ...rest
  } = props as Omit<IProps, 'article'>

  if (renderAs === 'div') {
    return (
      <ContainerElement
        renderAs="div"
        {...rest}
        className={classNames('tags', className, {
          'has-addons': hasAddons,
        })}
        innerRef={ref as React.ForwardedRef<HTMLDivElement>}
      >
        {children}
      </ContainerElement>
    )
  }
  if (renderAs === 'span') {
    return (
      <ContainerElement
        renderAs="span"
        {...rest}
        className={classNames('tags', className, {
          'has-addons': hasAddons,
        })}
        innerRef={ref as React.ForwardedRef<HTMLSpanElement>}
      >
        {children}
      </ContainerElement>
    )
  }

  return null
})

TagGroup.defaultProps = {
  hasAddons: undefined,
  renderAs: 'div',
}

export default TagGroup
