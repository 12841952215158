import classNames from 'classnames'
import React, { forwardRef } from 'react'
import BaseElements from '../../elements'
import { IDivElementProps } from '../../elements/types'

const { Div: Element } = BaseElements

const Container = forwardRef<HTMLDivElement, IDivElementProps>(({ className, ...props }, ref) => (
  <Element {...props} className={classNames('table-container', className)} ref={ref} />
))

export default Container
