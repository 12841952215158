import classNames from 'classnames'
import React from 'react'
import BaseElements from '../elements'
import { IContainerElementProps } from '../elements/types'

import { Color, Size } from '../types'

interface IconProps {
    icon?: string;
    size?: Exclude<Size, 'normal'> | 'auto';
    align?: 'left' | 'right';
    color?: Color;
    text?: boolean;
  }

const { Span: Element } = BaseElements

const Icon = React.forwardRef<HTMLSpanElement, Omit<IContainerElementProps, 'renderAs'> & IconProps>(({
  size, color, className, align, text, ...props
}, ref) => {
  if (text !== undefined) {
    // eslint-disable-next-line no-console
    console.warn(
      '[Deprecation] Text prop for Icon Component its deprecated, please use Icon.Text Component instead',
    )
  }
  return (
    <Element
      {...props}
      className={classNames(className, {
        icon: !text,
        [`is-${size}`]: size,
        [`is-${align}`]: align,
        [`has-text-${color}`]: color,
        'icon-text': text,
      })}
      ref={ref}
    />
  )
})

Icon.defaultProps = {
  icon: undefined,
  size: undefined,
  align: undefined,
  color: undefined,
  text: undefined,
}

export default Icon
