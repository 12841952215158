import classNames from 'classnames'
import React, { ReactElement } from 'react'
import Button from '../../../button'
import BaseElements from '../../../elements'
import { IContainerElementProps } from '../../../elements/types'
import Icon from '../../../icon/icon'
import { useFieldContext } from '../field/Context'

const { Div: Element } = BaseElements

interface ControlProps {
    fullwidth?: boolean,
    loading?: boolean,
    iconType?: React.ElementType
}

type IconAlignment = 'left' | 'right';

type IProps = Omit<IContainerElementProps, 'renderAs'> & ControlProps

const Control = React.forwardRef<HTMLDivElement, IProps>(({
  children,
  className,
  fullwidth,
  loading,
  iconType,
  ...props
}, ref) => {
  const context = useFieldContext()

  const isIcon = (child: ReactElement<{ align?: IconAlignment }>) => (
    child && child.type === (iconType || Icon)
        && 'align' in child.props && (child.props.align === 'left' || child.props.align === 'right')
  )

  const updatedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      if (!child || (!isIcon(child as ReactElement) && child.type !== Button)) {
        return child
      }
      return React.cloneElement<{size?: string}>(child as ReactElement, {
        size: child.props.size || context.size,
      })
    }
    return child
  })

  const icons = React.Children.toArray(updatedChildren)
    .filter((c) => (React.isValidElement(c) ? isIcon(c as ReactElement) : false))
    .reduce(
      (acc:{iconLeft: boolean, iconRight: boolean }, icon) => {
        if (React.isValidElement(icon)) {
          return ({
            iconLeft: acc.iconLeft || icon.props.align === 'left',
            iconRight: acc.iconRight || icon.props.align === 'right',
          })
        }
        return { iconLeft: false, iconRight: false }
      },
      { iconLeft: false, iconRight: false },
    )
  return (
    <Element
      {...props}
      className={classNames('control', className, {
        'is-expanded': fullwidth,
        'has-icons-left': icons.iconLeft,
        'has-icons-right': icons.iconRight,
        'is-loading': loading,
      })}
      ref={ref}
    >
      {updatedChildren}
    </Element>
  )
})

Control.defaultProps = {
  fullwidth: undefined,
  loading: undefined,
  iconType: undefined,
}

export default Control
