import { useContext } from 'react'
import { MaintenanceContext } from '../../providers/MaintenanceProvider'

const useMaintenanceStatus = () => {
  const value = useContext(MaintenanceContext)
  return {
    isLoading: value.isLoading,
    isUnderMaintenance: value.isUnderMaintenance,
    setMaintenanceStatus: value.updateMaintenanceStatus,
  }
}

export default useMaintenanceStatus
