import React, { useContext, useRef } from 'react'

const EditAdministratorContext = React.createContext<{
    isLoading:boolean,
    setIsLoading:(status:boolean) => void
      }>({
        isLoading: false,
        setIsLoading: () => {
        //
        },
      })

const useUpdateEditAdministratorLoadingStatus = () => {
  const { setIsLoading } = useContext(EditAdministratorContext)
  return useRef(setIsLoading).current
}

export { useUpdateEditAdministratorLoadingStatus }

export default EditAdministratorContext
