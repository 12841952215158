import React, { forwardRef, useMemo } from 'react'
import classNames from 'classnames'
import { IContainerElementProps, ResponsiveModifiers } from '../../elements/types'
import BaseElements from '../../elements'

const { Div } = BaseElements

type ColumnSize =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 'three-quarters'
  | 'two-thirds'
  | 'half'
  | 'one-third'
  | 'one-quarter'
  | 'one-fifth'
  | 'two-fifths'
  | 'three-fifths'
  | 'four-fifths';

interface ColumnBreakpointConfiguration {
    size?: ColumnSize;
    offset?: ColumnSize;
    narrow?: boolean;
  }

interface ColumnProps {
    size?: ColumnSize;
    offset?: ColumnSize;
    narrow?: boolean;
    touch?: ColumnBreakpointConfiguration & ResponsiveModifiers;
    mobile?: ColumnBreakpointConfiguration & ResponsiveModifiers;
    tablet?: ColumnBreakpointConfiguration & ResponsiveModifiers;
    desktop?: ColumnBreakpointConfiguration & ResponsiveModifiers;
    widescreen?: ColumnBreakpointConfiguration & ResponsiveModifiers;
    fullhd?: ColumnBreakpointConfiguration & ResponsiveModifiers;
  }

type IProps = Omit<IContainerElementProps, 'renderAs'> & ColumnProps

const Column = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const {
    children,
    className,
    size,
    offset,
    narrow,
    mobile,
    tablet,
    desktop,
    widescreen,
    fullhd,
    touch,
    ...rest
  } = props

  const classnames = useMemo(() => {
    const restClassNames = {} as { [key: string]: boolean }

    if (touch) {
      if ('size' in touch && touch.size != null) {
        restClassNames[`is-${touch.size}-mobile`] = true
      }
      if ('offset' in touch && touch.offset != null) {
        restClassNames[`is-offset-${touch.offset}-mobile`] = true
      }
    }

    if (mobile) {
      if ('size' in mobile && mobile.size != null) {
        restClassNames[`is-${mobile.size}-mobile`] = true
      }
      if ('offset' in mobile && mobile.offset != null) {
        restClassNames[`is-offset-${mobile.offset}-mobile`] = true
      }
    }

    if (tablet) {
      if ('size' in tablet && tablet.size != null) {
        restClassNames[`is-${tablet.size}-tablet`] = true
      }
      if ('offset' in tablet && tablet.offset != null) {
        restClassNames[`is-offset-${tablet.offset}-tablet`] = true
      }
    }

    if (desktop) {
      if ('size' in desktop && desktop.size != null) {
        restClassNames[`is-${desktop.size}-desktop`] = true
      }
      if ('offset' in desktop && desktop.offset != null) {
        restClassNames[`is-offset-${desktop.offset}-desktop`] = true
      }
    }

    if (widescreen) {
      if ('size' in widescreen && widescreen.size != null) {
        restClassNames[`is-${widescreen.size}-widescreen`] = true
      }
      if ('offset' in widescreen && widescreen.offset != null) {
        restClassNames[`is-offset-${widescreen.offset}-widescreen`] = true
      }
    }

    if (fullhd) {
      if ('size' in fullhd && fullhd.size != null) {
        restClassNames[`is-${fullhd.size}-fullhd`] = true
      }
      if ('offset' in fullhd && fullhd.offset != null) {
        restClassNames[`is-offset-${fullhd.offset}-fullhd`] = true
      }
    }

    return classNames(className, 'column', {
      [`is-${size}`]: size,
      ...restClassNames,
      [`is-offset-${offset}`]: offset,
      'is-narrow': narrow,
      'is-narrow-touch': touch && touch.narrow,
      'is-narrow-mobile': mobile && mobile.narrow,
      'is-narrow-tablet': tablet && tablet.narrow,
      'is-narrow-desktop': desktop && desktop.narrow,
      'is-narrow-widescreen': widescreen && widescreen.narrow,
      'is-narrow-fullhd': fullhd && fullhd.narrow,
    })
  }, [
    className,
    size,
    offset,
    narrow,
    mobile,
    tablet,
    desktop,
    widescreen,
    fullhd,
    touch,
  ])

  return (
    <Div
      {...rest}
      {...{
        mobile, tablet, desktop, widescreen, fullhd, touch,
      }}
      className={classnames}
      ref={ref}
    >
      {children}
    </Div>
  )
})

Column.defaultProps = {
  size: undefined,
  offset: undefined,
  narrow: undefined,
  touch: undefined,
  mobile: undefined,
  tablet: undefined,
  desktop: undefined,
  widescreen: undefined,
  fullhd: undefined,
}

export default Column
