import classNames from 'classnames'
import React from 'react'
import BaseElements from '../elements'

import { IHeadingElementProps } from '../elements/types'

const { Heading: Element } = BaseElements

interface HeadingProps {
    size?: 1 | 2 | 3 | 4 | 5 | 6;
    weight?: 'light' | 'normal' | 'semibold' | 'bold';
    subtitle?: boolean;
    heading?: boolean;
    spaced?: boolean;
  }

const Heading = React.forwardRef<HTMLHeadingElement, IHeadingElementProps & HeadingProps>(({
  children,
  className,
  size,
  subtitle,
  weight,
  spaced,
  heading,
  ...props
}, ref) => (
  <Element
    {...props}
    className={classNames(className, {
      title: !subtitle && !heading,
      subtitle,
      heading,
      [`is-${size}`]: size,
      [`has-text-weight-${weight}`]: weight,
      'is-spaced': spaced && !subtitle,
    })}
    ref={ref}
  >
    {children}
  </Element>
))

Heading.defaultProps = {
  size: undefined,
  weight: undefined,
  subtitle: undefined,
  heading: undefined,
  spaced: undefined,
}

export default Heading
