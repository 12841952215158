import classNames from 'classnames'
import React, { forwardRef } from 'react'
import BaseElements from '../../../elements'
import { IInputElementProps } from '../../../elements/types'

const { Label, Input } = BaseElements

const Radio = forwardRef<HTMLInputElement, IInputElementProps>(({
  className,
  style,
  disabled,
  checked,
  value,
  name,
  children,
  ...props
}, ref) => (
  <Label
    disabled={disabled}
    className={classNames('radio', className)}
    style={style}
  >
    <Input
      {...props}
      name={name}
      checked={checked}
      type="radio"
      value={value}
      disabled={disabled}
      ref={ref}
    />
    {' '}
    {children}
  </Label>
))

export default Radio
