import classNames from 'classnames'
import React, { forwardRef, useMemo } from 'react'
import {
  IContainerElementProps, ResponsiveModifiers, Breakpoint,
} from '../elements/types'
import BaseElements from '../elements'

interface GapProps {
  gap?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | string | number;
}

interface ColumnGroupProps {
  touch?: GapProps & ResponsiveModifiers;
  mobile?: GapProps & ResponsiveModifiers;
  tablet?: GapProps & ResponsiveModifiers;
  desktop?: GapProps & ResponsiveModifiers;
  widescreen?: GapProps & ResponsiveModifiers;
  fullhd?: GapProps & ResponsiveModifiers;
  breakpoint?: Breakpoint;
  gap?: GapProps['gap'];
  multiline?: boolean;
  centered?: boolean;
  vCentered?: boolean;
}

const { Div } = BaseElements

type ColumnsProps = Omit<IContainerElementProps, 'renderAs'> & ColumnGroupProps

const Columns = forwardRef<HTMLDivElement, ColumnsProps>((props, ref) => {
  const {
    children,
    className,
    breakpoint,
    gap,
    multiline,
    centered,
    vCentered,
    // variableGap,
    mobile,
    tablet,
    desktop,
    widescreen,
    fullhd,
    touch,
    ...rest
  } = props

  const classnames = useMemo(() => {
    const gapClassNames = {} as { [key: string]: boolean }

    if (touch && 'gap' in touch && touch.gap != null) {
      gapClassNames[`is-${touch.gap}-touch`] = true
    }
    if (mobile && 'gap' in mobile && mobile.gap != null) {
      gapClassNames[`is-${mobile.gap}-mobile`] = true
    }
    if (tablet && 'gap' in tablet && tablet.gap != null) {
      gapClassNames[`is-${tablet.gap}-tablet`] = true
    }
    if (desktop && 'gap' in desktop && desktop.gap != null) {
      gapClassNames[`is-${desktop.gap}-desktop`] = true
    }
    if (widescreen && 'gap' in widescreen && widescreen.gap != null) {
      gapClassNames[`is-${widescreen.gap}-widescreen`] = true
    }
    if (fullhd && 'gap' in fullhd && fullhd.gap != null) {
      gapClassNames[`is-${fullhd.gap}-fullhd`] = true
    }

    return classNames(className, 'columns', {
      [`is-${breakpoint}`]: breakpoint,
      [`is-${gap}`]: gap != null,
      'is-multiline': multiline,
      'is-centered': centered,
      'is-vcentered': vCentered,
      'is-variable':
        gap != null
        || [touch, mobile, tablet, desktop, widescreen, fullhd].find((b) => b && 'gap' in b != null),
      ...gapClassNames,
    })
  }, [
    className,
    breakpoint,
    gap,
    multiline,
    centered,
    vCentered,
    // variableGap,
    mobile,
    tablet,
    desktop,
    widescreen,
    fullhd,
    touch])

  return (
    <Div
      {...rest}
      {...{
        mobile, tablet, desktop, widescreen, fullhd, touch,
      }}
      className={classnames}
      ref={ref}
    >
      {children}
    </Div>
  )
})

Columns.defaultProps = {
  multiline: true,
  touch: undefined,
  mobile: undefined,
  tablet: undefined,
  desktop: undefined,
  widescreen: undefined,
  fullhd: undefined,
  breakpoint: undefined,
  gap: undefined,
  centered: undefined,
  vCentered: undefined,
}

export default Columns
