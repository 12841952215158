import Container from './components/container'
import TableInternal from './table'

type TableInternalType = typeof TableInternal

interface TableType extends TableInternalType {
    Container: typeof Container
}

const Table: TableType = TableInternal as TableType
Table.Container = Container

export default Table
