import classNames from 'classnames'
import React, { useEffect } from 'react'
import * as yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as bulmaToast from 'bulma-toast'
import { Button } from '@bespin-ui/react-ui-components'
import { Form } from '../../components/bulma'
import {
  ErrorBannerNotification, Label, Panel, PanelBody,
} from '../../components'
import { ROUTES } from '../../constants'
import { useEditAdministrator } from '../../hooks/api/admin'
import useFetchAdminById from '../../hooks/api/admin/useFetchAdminById'
import { useUpdateEditAdministratorLoadingStatus } from './EditAdministratorContext'
import useEnvironment from '../../hooks/env'

interface INavigationParams {
    adminId: string
}

const schema = yup.object({
  name: yup.string().required('is required').min(3),
})

interface IFormInputs {
    name: string
}

function EditAdministratorForm() {
  const setLoadingStatus = useUpdateEditAdministratorLoadingStatus()
  const env = useEnvironment()

  const navigate = useNavigate()

  const { adminId } = useParams<keyof INavigationParams>()

  const {
    isError, mutate, isLoading: isMutating, reset,
  } = useEditAdministrator()

  const {
    register, watch, formState: { errors, isValid },
    setValue, handleSubmit,
    clearErrors,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
    },
  })

  const { name } = watch()

  const { isFetching, data: adminCurrentInfo } = useFetchAdminById(adminId)

  const currentAdmin = adminCurrentInfo?.data

  useEffect(() => {
    if (currentAdmin) {
      setValue('name', currentAdmin.name)
      clearErrors('name')
    }
  }, [currentAdmin, setValue, clearErrors])

  const isLoading = isFetching || isMutating

  useEffect(() => {
    setLoadingStatus(isLoading)
  }, [isLoading, setLoadingStatus])

  const onSubmit = () => {
    if (!isLoading) {
      mutate({ adminId: adminId ?? '', editAdminName: name }, {
        onSuccess: () => {
          if (env !== 'test') {
            bulmaToast.toast({
              message: 'Username is successfully updated',
              type: 'is-success',
              dismissible: true,
              duration: 6000,
              position: 'bottom-right',
              animate: { in: 'fadeIn', out: 'fadeOut' },
            })
          }
          navigate(ROUTES.listAdministrator)
        },
      })
    }
  }

  return (
    <div>
      {isError && (
      <ErrorBannerNotification
        message="Unexpected error occurred!. Check your input and try again or contact admin."
        dismissButtonCallback={reset}
      />
      )}
      <Panel title="Edit Administrator">
        <PanelBody>
          <form onSubmit={(e) => { e.preventDefault() }}>
            <Form.Field>
              <Label label="Username" for="name" required />
              <Form.Control>
                <input
                  disabled={isFetching}
                  id="name"
                  data-testid="name"
                  className={classNames('input', { 'is-danger': errors.name != null })}
                  {...register('name')}
                />
              </Form.Control>
              <Form.Help color="danger">{errors.name != null ? errors.name.message : ''}</Form.Help>
            </Form.Field>
            <Form.Field className="is-flex is-justify-content-space-between mt-5">
              <Button
                isDisabled={!isValid || name === currentAdmin?.name || isLoading}
                label="Save"
                onClick={() => {
                  handleSubmit(onSubmit)()
                }}
              />
            </Form.Field>
          </form>
        </PanelBody>
      </Panel>
    </div>
  )
}

export default EditAdministratorForm
