import React, {
  useContext, useMemo, useRef, useState,
} from 'react'

export interface IAuth {
    isAuthenticated: boolean,
    isLoading: boolean,
    isAdmin: boolean,
    hasError: boolean,
}

interface IAuthContext extends IAuth {
    updateAuth?: React.Dispatch<React.SetStateAction<IAuth>>
}

export const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: false,
  isLoading: false,
  isAdmin: false,
  hasError: true,
})

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [{
    isAdmin, isAuthenticated, isLoading, hasError,
  }, setAuthState] = useState<IAuth>({
    isAdmin: false,
    isLoading: false,
    isAuthenticated: false,
    hasError: false,
  })

  const value = useMemo(() => ({
    isLoading,
    isAuthenticated,
    isAdmin,
    hasError,
    updateAuth: setAuthState,
  }), [isLoading,
    isAuthenticated,
    isAdmin,
    hasError,
    setAuthState])

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const {
    isAdmin, hasError, isAuthenticated, isLoading,
  } = useContext(AuthContext)
  return {
    isAdmin, hasError, isAuthenticated, isLoading,
  } as IAuth
}

type K = keyof IAuth
export const useAuthUpdate = () => {
  const { updateAuth } = useContext(AuthContext)

  // update one key
  const updateAuthOneValueRef = useRef((key: K, val: IAuth[K]) => {
    if (updateAuth != null) {
      updateAuth((prevState) => ({ ...prevState, [key]: val }))
    }
  })

  const updateRef = useRef((auth: IAuth) => {
    if (updateAuth != null) {
      updateAuth(auth)
    }
  })

  const value = useMemo(() => ({
    updateAuth: updateRef.current,
    updateAuthOneValue: updateAuthOneValueRef.current,
  }), [])

  return value
}

export default AuthProvider
