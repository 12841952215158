import React from 'react'
import classnames from 'classnames'
import {
  IDivElementProps,
  IButtonElementProps, IContainerElementProps,
  IInputElementProps,
  ILabelElementProps,
  ISelectElementProps,
  IParagraphElementProps,
  ResponsiveModifiers, ResponsiveProps, ISpanElementProps,
  ITableElementProps, ITextAreaElementProps, IHeadingElementProps,
  IArticleElementProps,
} from './types'

export const normalizeAlign = (align?: string) => {
  if (align === 'center' || align === 'justify') {
    const map = {
      justify: 'justifyed',
      center: 'centered',
    }
    return map[align]
  }
  return align
}

export const normalizeStatus = (status?: string) => {
  if (status === 'active' || status === 'focus' || status === 'hover') {
    const map = {
      focus: 'focused',
      hover: 'hovered',
      active: 'active',
    }
    return map[status]
  }
  return status
}

const buildResponsiveness = (
  currentViewport: keyof Omit<ResponsiveProps, 'display'> | 'until-widescreen' | 'until-fullhd',
  params?: ResponsiveModifiers & { only?: boolean },
) => {
  if (params) {
    const {
      display, textAlign, textSize, invisible,
    } = params
    const suffix = 'only' in params && params.only ? '-only' : ''
    return classnames({
      [`is-${display}-${currentViewport}${suffix}`]: display,
      [`has-text-${normalizeAlign(
        textAlign,
      )}-${currentViewport}${suffix}`]: textAlign,
      [`is-size-${textSize}-${currentViewport}${suffix}`]: textSize,
      [`is-invisible-${currentViewport}${suffix}`]: invisible,
    })
  }
  return ''
}

export const useElementClassNames = ({
  textColor,
  backgroundColor,
  colorVariant,
  flexDirection,
  flexWrap,
  justifyContent,
  alignContent,
  alignItems,
  flexGrow,
  alignSelf,
  // ratio,
  clearfix,
  paddingless,
  pull,
  marginless,
  overlay,
  clipped,
  radiusless,
  shadowless,
  unselectable,
  invisible,
  // clickable,
  srOnly,
  display,
  m,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
  p,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  textWeight,
  textTransform,
  italic,
  textSize,
  textAlign,
  textFamily,
  // responsive
  mobile,
  tablet,
  desktop,
  widescreen,
  fullhd,
  touch,
  untilWidescreen,
  untilFullhd,
  ...props
}: Omit<IContainerElementProps, 'renderAs' | 'innerRef'> | IButtonElementProps) => ({
  classNames: classnames(
    {
      [`has-text-${textColor}`]: textColor,
      [`has-background-${backgroundColor}`]: backgroundColor,
      [`is-${colorVariant}`]: colorVariant,
      [`is-flex-direction-${flexDirection}`]: flexDirection,
      [`is-flex-wrap-${flexWrap}`]: flexWrap,
      [`is-justify-content-${justifyContent}`]: justifyContent,
      [`is-align-content-${alignContent}`]: alignContent,
      [`is-align-items-${alignItems}`]: alignItems,
      [`is-align-self-${alignSelf}`]: alignSelf,
      [`is-flex-grow-${flexGrow}`]: flexGrow,
      'is-clearfix': clearfix,
      [`is-pulled-${pull}`]: pull,
      'is-marginless': marginless,
      'is-paddingless': paddingless,
      'is-overlay': overlay,
      'is-clipped': clipped,
      'is-radiusless': radiusless,
      'is-shadowless': shadowless,
      'is-unselectable': unselectable,
      [`is-${display}`]: display,
      'is-invisible': invisible,
      'is-sr-only': srOnly,
      // // 'is-clickable': clickable,
      [`m-${m}`]: typeof m === 'number' && !Number.isNaN(m),
      [`mt-${mt}`]: typeof mt === 'number' && !Number.isNaN(mt),
      [`mr-${mr}`]: typeof mr === 'number' && !Number.isNaN(mr),
      [`mb-${mb}`]: typeof mb === 'number' && !Number.isNaN(mb),
      [`ml-${ml}`]: typeof ml === 'number' && !Number.isNaN(ml),
      [`mx-${mx}`]: typeof mx === 'number' && !Number.isNaN(mx),
      [`my-${my}`]: typeof my === 'number' && !Number.isNaN(my),
      [`p-${p}`]: typeof p === 'number' && !Number.isNaN(p),
      [`pt-${pt}`]: typeof pt === 'number' && !Number.isNaN(pt),
      [`pr-${pr}`]: typeof pr === 'number' && !Number.isNaN(pr),
      [`pb-${pb}`]: typeof pb === 'number' && !Number.isNaN(pb),
      [`pl-${pl}`]: typeof pl === 'number' && !Number.isNaN(pl),
      [`px-${px}`]: typeof px === 'number' && !Number.isNaN(px),
      [`py-${py}`]: typeof py === 'number' && !Number.isNaN(py),
      [`has-text-${normalizeAlign(textAlign)}`]: textAlign,
      [`has-text-weight-${textWeight}`]: textWeight,
      [`is-size-${textSize}`]: textSize,
      [`is-${textTransform}`]: textTransform,
      [`is-family-${textFamily}`]: textFamily,
      'is-italic': italic,
    },
    buildResponsiveness('mobile', mobile),
    buildResponsiveness('tablet', tablet),
    buildResponsiveness('desktop', desktop),
    buildResponsiveness('widescreen', widescreen),
    buildResponsiveness('fullhd', fullhd),
    buildResponsiveness('touch', touch),
    buildResponsiveness('until-widescreen', untilWidescreen),
    buildResponsiveness('until-fullhd', untilFullhd),
  ),
  props,
})

function ContainerElement(props: IContainerElementProps) {
  const {
    renderAs, className, innerRef, children, ...rest
  } = props
  const { classNames, props: remainingProps } = useElementClassNames(rest)
  if (renderAs === 'div') {
    return (
      <div
        className={classnames(className, classNames) || undefined}
        {...remainingProps as IContainerElementProps}
        ref={innerRef as React.ForwardedRef<HTMLDivElement> | undefined}
      >
        {children}
      </div>
    )
  }
  return (
    <span
      className={classnames(className, classNames) || undefined}
      {...remainingProps}
      ref={innerRef as React.ForwardedRef<HTMLSpanElement> | undefined}
    >
      {children}
    </span>
  )
}

const Div = React.forwardRef<HTMLDivElement, IDivElementProps>(({ children, ...rest }, ref) => (
  <ContainerElement renderAs="div" {...rest} innerRef={ref}>
    {children}
  </ContainerElement>
))

const Article = React.forwardRef<HTMLElement, IArticleElementProps>(({
  children, ...rest
}, ref) => (
  <ContainerElement renderAs="article" {...rest} innerRef={ref}>
    {children}
  </ContainerElement>
))

const Span = React.forwardRef<HTMLSpanElement, ISpanElementProps>(({ children, ...rest }, ref) => (
  <ContainerElement renderAs="span" {...rest} innerRef={ref}>
    {children}
  </ContainerElement>
))

const P = React.forwardRef<HTMLParagraphElement, IParagraphElementProps>(({
  children,
  ...rest
}, ref) => (
  <ContainerElement renderAs="p" {...rest} innerRef={ref}>
    {children}
  </ContainerElement>
))

const ButtonElement = React.forwardRef<HTMLButtonElement, IButtonElementProps>((props, ref) => {
  const {
    className, children, ...rest
  } = props
  const { classNames, props: remainingProps } = useElementClassNames(rest)

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={classnames(className, classNames) || undefined}
      {...remainingProps as IButtonElementProps}
      ref={ref}
    >
      {children}
    </button>
  )
})

const InputElement = React.forwardRef<HTMLInputElement, IInputElementProps>((props, ref) => {
  const {
    className, ...rest
  } = props
  const { classNames, props: remainingProps } = useElementClassNames(rest)
  return (
    <input
      className={classnames(className, classNames) || undefined}
      {...remainingProps as IInputElementProps}
      ref={ref}
    />
  )
})

const TextAreaElement = React.forwardRef<HTMLTextAreaElement, ITextAreaElementProps>((
  props,
  ref,
) => {
  const {
    className, ...rest
  } = props
  const { classNames, props: remainingProps } = useElementClassNames(rest)
  return (
    <textarea
      className={classnames(className, classNames) || undefined}
      {...remainingProps as ITextAreaElementProps}
      ref={ref}
    />
  )
})

const LabelElement = React.forwardRef<HTMLLabelElement, ILabelElementProps>((props, ref) => {
  const {
    className, children, htmlFor, ...rest
  } = props
  const { classNames, props: remainingProps } = useElementClassNames(rest)
  return (
    <label
      className={classnames(className, classNames) || undefined}
      {...remainingProps as ILabelElementProps}
      ref={ref}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  )
})

const SelectElement = React.forwardRef<HTMLSelectElement, ISelectElementProps>((props, ref) => {
  const {
    className, children, ...rest
  } = props
  const { classNames, props: remainingProps } = useElementClassNames(rest)

  return (
    <select
      className={classnames(className, classNames) || undefined}
      {...remainingProps as ISelectElementProps}
      ref={ref}
    >
      {children}
    </select>
  )
})

const TableElement = React.forwardRef<HTMLTableElement, ITableElementProps>((props, ref) => {
  const {
    className, children, ...rest
  } = props
  const { classNames, props: remainingProps } = useElementClassNames(rest)

  return (
    <table
      className={classnames(className, classNames) || undefined}
      {...remainingProps as ITableElementProps}
      ref={ref}
    >
      {children}
    </table>
  )
})

const HeadingElement = React.forwardRef<HTMLHeadingElement, IHeadingElementProps>(({
  renderAs,
  className,
  children, ...rest
}, ref) => {
  const { classNames, props } = useElementClassNames(rest)

  const Component = renderAs ?? 'h1'
  return (
    <Component
      className={classnames(className, classNames) || undefined}
      {...props as IHeadingElementProps}
      ref={ref}
    >
      {children}
    </Component>
  )
})
HeadingElement.defaultProps = {
  renderAs: 'h1',
}

const BaseElements = {
  Div,
  Span,
  P,
  Article,
  Heading: HeadingElement,
  Button: ButtonElement,
  Input: InputElement,
  TextArea: TextAreaElement,
  Label: LabelElement,
  Select: SelectElement,
  Table: TableElement,
}

export { ContainerElement }

export default BaseElements
