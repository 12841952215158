import axios, { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import IServer from '../../../types/IServer'
import { useDBaaSClient } from '../../client'

interface IServerBody extends IServer {
    user:string,
    password:string
}

interface IError {
    message: string
}

const useUpdateServer = () => {
  const { put } = useDBaaSClient()

  const queryClient = useQueryClient()

  const queryResult = useMutation((body: IServerBody) => put('/servers', body), {
    onSuccess: (_, v) => {
      queryClient.invalidateQueries('servers')
      queryClient.invalidateQueries(['servers', v._id])
    },
  })

  const error = useMemo(() => {
    const defaultErrorMessage = 'Unexpected error occurred while updating server!. Please try again or contact DBaaS support'
    if (queryResult.isError) {
      if (queryResult.error && axios.isAxiosError(queryResult.error)) {
        const errRes = queryResult.error as AxiosError<IError>
        if (errRes.response?.data) {
          return { message: errRes.response.data.message }
        }
        return { message: defaultErrorMessage }
      }
      return Error(defaultErrorMessage)
    }
    return null
  }, [queryResult.error, queryResult.isError])

  return { ...queryResult, error }
}

export default useUpdateServer
