import { faCaretLeft, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { ROUTES, TITLES } from '../../constants'
import { DetailsTable, ErrorBannerNotification } from '../../components'
import { Columns, Container } from '../../components/bulma'
import { useFetchEventById } from '../../hooks/api/events'
import AppLayout from '../../layouts'

interface INavigationParams {
    eventId: string
}

function ViewEventDetails() {
  const { eventId } = useParams<keyof INavigationParams>()

  const { data: eventResponse, isFetching: isEventLoading, isError } = useFetchEventById(eventId)
  const event = eventResponse?.data

  const data = useMemo(() => {
    const details = [
      { name: 'ID', value: event?._id ?? '' },
      { name: 'Event Type', value: event?.eventType ?? '' },
      { name: 'Provider Name', value: event?.providerName ?? '' },
      { name: 'Database Name', value: event?.databaseName ?? '' },
      { name: 'Database ID', value: event?.dbaasDatabase ?? '' },
      { name: 'User', value: event?.user ?? '' },
      { name: 'Created By', value: event?.createdBy ?? '' },
      { name: 'Admin username', value: event?.adminUserName ?? '' },
      { name: 'Server Id', value: event?.serverId ?? '' },
      { name: 'Taxonomy Id', value: event?.taxonomyId.toString() ?? '' },
      { name: 'Owners', value: event?.owners ?? '' },
      { name: 'Created At', value: event?.createdAt ?? '' },
      { name: 'Updated At', value: event?.updatedAt ?? '' },
    ]
    return details
  }, [event])

  return (
    <>
      <Helmet>
        <title>{TITLES.viewEventDetails}</title>
      </Helmet>
      <AppLayout isLoading={isEventLoading}>
        <Container className="is-fluid">
          <Columns multiline={false} className="mt-5">
            <Columns.Column>
              <h4 className="title is-4">
                <Link to={ROUTES.listEvents} replace>
                  <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
                </Link>
                <FontAwesomeIcon className="pr-3" icon={faDatabase} />
                Events &gt; View
                {' '}
              </h4>
              {isError && (
                <ErrorBannerNotification
                  message="Failed to fetch event information. Please contact DBaaS support."
                />
              )}
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <DetailsTable data={data} />
            </Columns.Column>
          </Columns>
        </Container>
      </AppLayout>
    </>
  )
}

export default ViewEventDetails
