import { useQuery } from 'react-query'
import { TEventPreview } from '../../../types/events'
import { useDBaaSClient } from '../../client'
import { useFetchIsAdmin } from '../admin'

const useFetchEvents = () => {
  const { get } = useDBaaSClient()

  const { isFetching: isLoading, isSuccess } = useFetchIsAdmin()
  const canFetch = !isLoading && isSuccess

  return useQuery(['events'], () => get<TEventPreview[] | undefined>('/events'), { enabled: canFetch })
}

export default useFetchEvents
