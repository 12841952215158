import React, { useEffect, useRef, useState } from 'react'
import { Label, Panel, PanelBody } from '../../../components'
import { Button, Form } from '../../../components/bulma'

interface IProps {
    onChange?: (id: string) => void
}

const tempOrganizationValues = [
  'Mix Disney',
  'Stream Disney',
  'Trust Disney',
]

function Organization(props: IProps) {
  const { onChange } = props

  const [selectionValue, setSelectionValue] = useState<'createOrg' | 'selectOrg'>()

  const [organization, setOrganization] = useState('')

  const [selectedOrganization, setSelectedOrganization] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const [organizations, setOrganizations] = useState(tempOrganizationValues)

  const onChangeRef = useRef(onChange)

  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])

  useEffect(() => {
    if (onChangeRef.current) { onChangeRef.current(selectedOrganization) }
  }, [selectedOrganization])

  return (
    <Panel className="mt-5" titleClassName="" title={selectedOrganization ? `Selected organization: ${selectedOrganization}` : undefined}>
      <PanelBody>
        <Panel title="Organization">
          <PanelBody>
            <Form.Control>
              <Form.Radio
                checked={selectionValue === 'createOrg'}
                onChange={() => { setSelectionValue('createOrg') }}
                value="createOrg"
                className="mr-3"
              >
                Create organization
              </Form.Radio>
              <Form.Radio
                checked={selectionValue === 'selectOrg'}
                onChange={() => { setSelectionValue('selectOrg') }}
                value="selectOrg"
              >
                Select organization
              </Form.Radio>
            </Form.Control>
            {selectionValue === 'selectOrg' && (
            <Form.Field>
              <Label label="Organizations" for="selectOrganization" required />
              <Form.Control>
                <Form.Select
                  value={selectedOrganization ?? ''}
                  // defaultValue=""
                  fullwidth
                  id="selectOrganization"
                  name="selectOrganization"
                  onChange={(e) => {
                    setSelectedOrganization(e.target.value)
                  }}
                >
                  <option value="" disabled>Select Organization</option>
                  {organizations.map((p, i) => <option key={`${p + i}`} value={p}>{p}</option>)}
                </Form.Select>
              </Form.Control>
            </Form.Field>
            )}
            {selectionValue === 'createOrg' && (
            <>
              <Form.Field>
                <Label label="Organization Name" for="selectOrganization" required />
                <Form.Control>
                  <Form.Input
                    disabled={isLoading}
                    value={organization}
                    onChange={(e) => { setOrganization(e.target.value.trim()) }}
                  />
                </Form.Control>
              </Form.Field>
              <Form.Field display="flex" mt={5} flexDirection="row-reverse">
                <Button
                  disabled={isLoading || (organization.trim()).length === 0}
                  color="success"
                  type="submit"
                  onClick={() => {
                    setIsLoading(true)
                    setTimeout(() => {
                      setIsLoading(false)
                      setOrganizations((currentProjects) => {
                        const newArray = [...currentProjects]
                        if (organization) {
                          newArray.push(organization)
                        }
                        return newArray
                      })
                      setSelectedOrganization(organization)
                      setSelectionValue('selectOrg')
                      setOrganization('')
                    }, 3000)
                  }}
                >
                  Submit
                </Button>
              </Form.Field>
            </>
            )}
          </PanelBody>
        </Panel>
      </PanelBody>
    </Panel>
  )
}

Organization.defaultProps = {
  onChange: undefined,
}

export default Organization
