import React, {
  useContext, useEffect, useMemo, useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as bulmaToast from 'bulma-toast'
import { useFetchServerById } from '../../hooks/api/server'
import ROUTES from '../../constants/ROUTES'
import IServer from '../../types/IServer'

interface IEditServerByIdContext {
    isLoading: boolean,
    setLoadingStatus?: (isLoading: boolean) => void,
  initialServerData?: IServer,
}

interface INavigationParams {
  serverID: string
}

const EditServerByIdContext = React.createContext<IEditServerByIdContext>({
  isLoading: false,
})

function EditServerByIProvider({ children }:{children:JSX.Element}) {
  const [isLoading, setLoadingStatus] = useState(false)
  const [initialServerData, setInitialServerData] = useState<IServer>()

  const navigate = useNavigate()
  const { serverID } = useParams<keyof INavigationParams>()

  useEffect(() => {
    if (serverID == null) {
      navigate(ROUTES.listServers)
    }
  }, [navigate, serverID])

  const {
    data: serverResponse, isFetching, isError,
  } = useFetchServerById(serverID)

  useEffect(() => {
    setLoadingStatus(isFetching)
  }, [isFetching])

  useEffect(() => {
    if (serverResponse?.data) {
      setInitialServerData(serverResponse.data)
    }
  }, [serverResponse])

  useEffect(() => {
    if (isError) {
      bulmaToast.toast({
        message: 'Unexpected error occurred, try again',
        type: 'is-danger',
        dismissible: true,
        duration: 6000,
        position: 'bottom-right',
        animate: { in: 'fadeIn', out: 'fadeOut' },
      })
      navigate(ROUTES.listServers, { replace: true })
    }
  }, [isError, navigate])

  const value = useMemo(() => ({
    isLoading,
    setLoadingStatus,
    initialServerData,
  }), [isLoading, initialServerData])

  return (
    <EditServerByIdContext.Provider value={value}>
      {children}
    </EditServerByIdContext.Provider>
  )
}

const EditServerByIdConsumer = EditServerByIdContext.Consumer

const useChangeLoadingStatus = () => {
  const updater = useContext(EditServerByIdContext).setLoadingStatus as (isLoading: boolean) => void
  return updater
}
const useGetInitialServerData = () => useContext(EditServerByIdContext).initialServerData

export {
  EditServerByIProvider, useChangeLoadingStatus, useGetInitialServerData, EditServerByIdConsumer,
}

export default EditServerByIdContext
