import classNames from 'classnames'
import React from 'react'
import BaseElements from '../elements'
import { ITableElementProps } from '../elements/types'

interface TableProps {
    size?: 'fullwidth' | 'narrow';
    striped?: boolean;
    bordered?: boolean;
    hoverable?: boolean;
  }

  type IProps = TableProps & ITableElementProps

const { Table: Element } = BaseElements

const Table = React.forwardRef<HTMLTableElement, IProps>(({
  children,
  className,
  size,
  striped,
  bordered,
  hoverable,
  ...props
}, ref) => (
  <Element
    {...props}
    className={classNames('table', className, {
      [`is-${size}`]: size,
      'is-bordered': bordered,
      'is-striped': striped,
      'is-hoverable': hoverable,
    })}
    ref={ref}
  >
    {children}
  </Element>
))

Table.defaultProps = {
  size: undefined,
  striped: undefined,
  bordered: undefined,
  hoverable: undefined,
}

export default Table
