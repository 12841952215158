import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { TITLES } from '../../constants'
import ROUTES from '../../constants/ROUTES'
import { useFetchIsAdmin } from '../../hooks/api/admin'
import { EditAdministratorForm, EditAdministratorLayout } from '../../modules/editAdministrator'
import { useAuth } from '../../providers/AuthProvider'

interface INavigationParams {
    adminId: string
}

function EditAdministrator() {
  const navigate = useNavigate()

  const { adminId } = useParams<keyof INavigationParams>()

  const { isAuthenticated } = useAuth()

  useFetchIsAdmin({ enabled: !isAuthenticated })

  useEffect(() => {
    if (!adminId) {
      navigate(ROUTES.listAdministrator, { replace: true })
    }
  }, [adminId, navigate])

  return (
    <>
      <Helmet>
        <title>{TITLES.editAdministrator}</title>
      </Helmet>
      <EditAdministratorLayout>
        {adminId != null ? <EditAdministratorForm /> : ''}
      </EditAdministratorLayout>
    </>
  )
}

export default EditAdministrator
