import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { Button } from '@bespin-ui/react-ui-components'
import { ROUTES } from '../../constants'
import {
  Label, Panel, PanelBody, OwnersTable,
} from '../../components'
import { IPrimaryFields, IDatabaseCreateFields } from '../../types/createDatabase'
import { Form, Icon } from '../../components/bulma'

// CreateDatabase
interface CreateDatabaseFormProps extends Partial<IPrimaryFields> {
  isPrimaryFormValid: boolean,
  taxonomyId: string | undefined
}

const schema = yup.object({
  capacity: yup.number().typeError('Must be an integer.').positive().required('is required')
    .min(1)
    .max(10, 'Must be no more than 10 GBs.'),
  databaseName: yup.string().required('is required')
    .matches(/^[a-zA-Z0-9_]+$/, { message: 'Must only contain alphanumeric characters. Underscore is also allowed' })
    .min(1)
    .max(64, 'Must be shorter than 64 characters'),
  user: yup.string().required('is required')
    .matches(/^[a-zA-Z][0-9a-zA-Z_]*$/, { message: 'Must begin with alpha and only contain alphanumeric characters. Underscore is also allowed' })
    .min(4, 'Must be longer than 3 chars.')
    .max(128, 'Must be shorter than 129 characters.'),
  password: yup.string()
    .required('is required')
    .notOneOf([yup.ref('user')], 'cannot be username')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@&^#$!`*'\-%])[A-Za-z\d\\@&^#$!`*'\-%]{10,128}$/,
      { message: "Password should meet specified requirements. It should satisfy all of these combinations: length 10-128, [a-z], [A-Z], numbers, @, &, ^, #, $, !, `, *, ', -, %,;, cannot be username" },
    ),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Password must match'),
  owners: yup.array().of(yup.string()),
})

function CreateDatabaseForm(props: CreateDatabaseFormProps) {
  const { isPrimaryFormValid, taxonomyId } = props
  const navigate = useNavigate()
  const { pathname, state } = useLocation()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const { canEditCreate } = useMemo(() => {
    const isEdit = pathname === ROUTES.createDatabaseEdit
    if (isEdit) {
      const isValidInitialEditValue = schema.isValidSync(state)
      const canEdit = isValidInitialEditValue && isEdit
      return { canEditCreate: canEdit }
    }
    return { canEditCreate: false }
  }, [pathname, state])

  useEffect(() => {
    if (!canEditCreate) {
      navigate(ROUTES.createDatabase, { replace: true })
    }
  }, [canEditCreate, navigate])

  const initialValues = useMemo(() => {
    if (!canEditCreate) {
      return {
        databaseName: '',
        capacity: undefined,
        user: '',
        password: '',
        passwordConfirmation: '',
        owners: [],
      }
    }

    const tempState = state as Partial<IDatabaseCreateFields>
    return tempState
  }, [state, canEditCreate])

  const {
    watch, formState: { errors, isValid }, handleSubmit, setValue, clearErrors, setError, register,
  } = useForm<IDatabaseCreateFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: initialValues,
  })
  const values = watch()

  const onSubmit = () => {
    navigate(ROUTES.databaseCreatedConfirm, { state: { ...values, ...props } })
  }

  const onCancel = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (!errors.password && values.password === values.passwordConfirmation) {
      clearErrors('passwordConfirmation')
    } else if (errors.password && values.passwordConfirmation.trim() !== '') {
      setError('passwordConfirmation', { type: 'validate', message: 'Password must match' })
    }
  }, [values.password, values.passwordConfirmation, clearErrors, setError, errors.password])

  return (
    <>
      <Form.Field>
        <Label label="Database Name" required for="databaseName" />
        <Form.Control>
          <input className={classNames('input', { 'is-danger': errors.databaseName != null })} {...register('databaseName')} id="databaseName" />
        </Form.Control>
        {errors.databaseName && <Form.Help color="danger">{errors.databaseName.message}</Form.Help>}
      </Form.Field>

      <Form.Field>
        <Label label="Size" required />
        <Form.Control>
          <input className={classNames('input', { 'is-danger': errors.capacity != null })} {...register('capacity')} id="capacity" />
        </Form.Control>
        <Form.Help color="danger">{errors.capacity != null ? errors.capacity.message : ''}</Form.Help>
      </Form.Field>

      <Form.Field>
        <Label label="Admin Username" required />
        <Form.Control>
          <input className={classNames('input', { 'is-danger': errors.user != null })} {...register('user')} id="user" />
        </Form.Control>
        <Form.Help color="danger">{errors.user != null ? errors.user.message : ''}</Form.Help>
      </Form.Field>

      <Form.Field>
        <Label label="Admin Password" required />
        <Form.Control>
          <input type={showPassword ? 'text' : 'password'} className={classNames('input', { 'is-danger': errors.password != null })} {...register('password')} id="password" />
          <Icon align="right">
            <FontAwesomeIcon icon={faEye} color="rgb(74, 74, 74)" cursor="pointer" pointerEvents="initial" onClick={() => setShowPassword(!showPassword)} />
          </Icon>
        </Form.Control>
        <Form.Help color="danger">{errors.password != null ? errors.password.message : ''}</Form.Help>

      </Form.Field>
      <Form.Field>
        <Label label="Admin Password Confirmation" required />
        <Form.Control>
          <input type={showConfirmPassword ? 'text' : 'password'} className={classNames('input', { 'is-danger': errors.passwordConfirmation != null })} {...register('passwordConfirmation')} id="passwordConfirmation" />
          <Icon align="right">
            <FontAwesomeIcon icon={faEye} color="rgb(74, 74, 74)" cursor="pointer" pointerEvents="initial" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
          </Icon>
        </Form.Control>
        <Form.Help color="danger">{errors.passwordConfirmation != null ? errors.passwordConfirmation.message : ''}</Form.Help>
      </Form.Field>
      <Panel className="mt-5">
        <PanelBody>
          <Panel title="Additional Owners">
            <PanelBody>
              <OwnersTable
                initialValues={values.owners}
                onChange={(b) => {
                  setValue('owners', b)
                }}
              />
            </PanelBody>
          </Panel>
        </PanelBody>
      </Panel>
      <Form.Field className="is-flex is-justify-content-space-between mt-5">
        <Button
          onClick={onCancel}
          variant="secondary"
          label="Cancel"
        />

        <Button
          label="Submit"
          variant="primary"
          isDisabled={!isValid || !isPrimaryFormValid || taxonomyId == null}
          onClick={handleSubmit(onSubmit)}
        />
      </Form.Field>
    </>
  )
}

export default CreateDatabaseForm
