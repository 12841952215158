import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Table, Tag } from '../bulma'

interface IDataType {
  type: 'Primary' | 'Secondary',
  value: string | ReactNode,
  status: 'Active' | 'Inactive'
}

interface IProps {
  data: IDataType[]
}

const CellContainer = styled.div`
    width: 100%;
    display: inline-flex;
    span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

function NodesTable({ data }: IProps) {
  return (
    <Table bordered hoverable size="fullwidth">
      <tbody>
        {
          data.map((value, index) => (
            <tr key={`${`${index}`}`}>
              <td>
                <CellContainer>
                  {typeof value.value === 'string' ? (
                    <strong>
                      {' '}
                      {value.value}
                      {' '}
                    </strong>
                  ) : value.value}
                </CellContainer>
              </td>
              <td width="15 %"><CellContainer><Tag>{value.type}</Tag></CellContainer></td>
              <td width="15 %"><CellContainer>{value.status}</CellContainer></td>
            </tr>
          ))
        }

      </tbody>
    </Table>
  )
}

export default NodesTable
