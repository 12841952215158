import axios, { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useMutation } from 'react-query'
import { useDBaaSClient } from '../../client'

interface IError {
  message: string
}

const useResetDatabasePassword = ({ onSettled }: { onSettled?: () => void }) => {
  const client = useDBaaSClient()

  const queryResult = useMutation(({ databaseID, password }: { databaseID: string, password: string }) => client.post(`/databases/${databaseID}/reset_password`, {
    password,
  }), { onSettled })

  const error = useMemo(() => {
    const defaultErrorMessage = 'Unexpected error occurred while updating password!. Please try again or contact admin.'
    if (queryResult.isError) {
      if (queryResult.error && axios.isAxiosError(queryResult.error)) {
        const errRes = queryResult.error as AxiosError<IError>
        if (errRes.response?.data) {
          return { message: errRes.response.data.message }
        }
        return { message: defaultErrorMessage }
      }
      return Error(defaultErrorMessage)
    }
    return null
  }, [queryResult.error, queryResult.isError])

  return { ...queryResult, error }
}

export default useResetDatabasePassword
