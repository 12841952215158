import { useQuery } from 'react-query'
import IAdministrator from '../../../types/admin'
import { useDBaaSClient } from '../../client'

const useFetchAdminById = (adminID?: string) => {
  const { get } = useDBaaSClient()

  return useQuery(['admins', adminID], () => get<IAdministrator>(`/admins/${adminID}`), {
    enabled: adminID != null,
  })
}

export default useFetchAdminById
