import { faCaretLeft, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container } from '../../components/bulma'
import ROUTES from '../../constants/ROUTES'
import AppLayout from '../../layouts'
import EditAdministratorContext from './EditAdministratorContext'

function EditAdministratorLayout({ children }: { children: JSX.Element | string }) {
  const [isLoading, setIsLoading] = useState(false)
  const contextValue = useMemo(() => ({ isLoading, setIsLoading }), [isLoading])
  return (
    <EditAdministratorContext.Provider value={contextValue}>
      <AppLayout isLoading={contextValue.isLoading}>
        <Container className="is-fluid">
          <h4 className="title is-4">
            <Link to={ROUTES.listAdministrator} replace>
              <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
            </Link>
            <FontAwesomeIcon className="pr-3" icon={faDatabase} />
            Administrators &gt; Edit
          </h4>
          {children}
        </Container>
      </AppLayout>
    </EditAdministratorContext.Provider>
  )
}

export default EditAdministratorLayout
