import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Container as BulmaContainer, Heading } from '../bulma'
import useMaintenanceStatus from '../../hooks/maintenance'
import { useAuth } from '../../providers/AuthProvider'
import { useFetchIsAdmin } from '../../hooks/api/admin'

const Spacer = styled.div`
    margin-top: 100px;
    width: 100%;
`
const Container = styled(BulmaContainer)`
  height: 100vh;
`
const MessageContainer = styled(BulmaContainer)`
  width: 45%;
`
const getMaintenanceCustomMessage = () => {
  try {
    const data = JSON.parse(localStorage.getItem('maintenance') ?? '')
    if (data && 'message' in data) {
      return data.message ?? ''
    }
    return ''
  } catch (error) {
    return ''
  }
}

function Maintenance() {
  const { isAdmin, isLoading, isAuthenticated } = useAuth()

  useFetchIsAdmin({ enabled: !isAuthenticated })

  const navigate = useNavigate()
  const { setMaintenanceStatus } = useMaintenanceStatus()
  return (
    <Container textAlign="center" alignItems="center" display="flex" flexDirection="column" justifyContent="space-between">
      <Spacer>
        <Heading size={4}>
          DBaaS is under Maintenance
        </Heading>

        <MessageContainer pt={6} textAlign="center">
          <Heading renderAs="h2" subtitle size={6}>
            {getMaintenanceCustomMessage() ? getMaintenanceCustomMessage() : 'DBaaS is under Maintenance' }
          </Heading>
        </MessageContainer>
      </Spacer>
      {(isAdmin || isLoading) && (
      <Button
        color="ghost"
        loading={isLoading}
        outlined
        pb={6}
        onClick={() => {
          if (!isLoading && isAdmin) {
            navigate('/')
            setMaintenanceStatus({ isLoading: false, isUnderMaintenance: false })
            localStorage.clear()
          }
        }}
      >
        Administrators: Click here to turn off this page and enable DBaaS application
      </Button>
      )}
    </Container>
  )
}

export default Maintenance
