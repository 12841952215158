import React, { useContext } from 'react'
import { Size } from './types'

const FieldContext = React.createContext<{size?: Size}>({
  size: undefined,
})

export const useFieldContext = () => useContext(FieldContext)

export default FieldContext
