import { useQuery } from 'react-query'
import { useTaxonomyClient } from '../../client'

interface IAffiliate {
  _id: number,
  businessUnitId: number,
  affiliateName: string,
  affiliateId: number,
  subAffiliateId: number
}

const useFetchAffiliates = (segment: string, businessUnit: string) => {
  const { get } = useTaxonomyClient()

  return useQuery(['affiliate', segment, businessUnit], () => get<IAffiliate[]>('/affiliate', {
    params: {
      segment,
      businessUnit,
    },
  }), { enabled: segment.length > 0 && businessUnit.length > 0 })
}

export default useFetchAffiliates
