import classNames from 'classnames'
import React, { forwardRef } from 'react'
import BaseElements from '../../../elements'
import { ILabelElementProps } from '../../../elements/types'
import { Size } from '../../../types'
import { useFieldContext } from '../field/Context'

const { Label: Element } = BaseElements

const Label = forwardRef<HTMLLabelElement, ILabelElementProps & {size?: Exclude<Size, 'normal'>}>(({
  children, className, size, ...props
}, ref) => {
  const context = useFieldContext()
  const calculatedSize = size || context.size
  return (
    <Element
      {...props}
      className={classNames('label', className, {
        [`is-${calculatedSize}`]: calculatedSize,
      })}
      ref={ref}
    >
      {children}
    </Element>
  )
})

Label.defaultProps = {
  size: undefined,
}

export default Label
