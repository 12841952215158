import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { ColumnDef } from '@tanstack/table-core'
import { ROUTES, TITLES } from '../../constants'
import { ActionButtons, Label, Table } from '../../components'
import { Columns, Container, Form } from '../../components/bulma'
import { useFetchServiceLogNames, useFetchServiceLogsByName } from '../../hooks/api/serviceLog'
import AppLayout from '../../layouts'
import IServiceLog from '../../types/serviceLog'

function ListServiceLogs() {
  const navigate = useNavigate()
  const [selectedServiceLogName, setSelectedServiceLogName] = useState('')

  const {
    data: serviceLogNamesResponse,
    isFetching: isServiceLogNamesLoading,
  } = useFetchServiceLogNames({
    onSuccess: ({ data }) => {
      if (data.length > 0) {
        setSelectedServiceLogName(data[0])
      }
    },
  })

  const serviceLogNames = serviceLogNamesResponse?.data ?? []

  const {
    data: serviceLogsResponse,
    isFetching: isServerLogsLoading,
  } = useFetchServiceLogsByName(selectedServiceLogName)

  const columns: ColumnDef<IServiceLog>[] = React.useMemo(() => [
    {
      header: 'ID',
      accessorKey: '_id',
      id: '_id',
      meta: {
        width: '40%',
      },
    },
    {
      header: 'Log Message',
      accessorKey: 'message',
      id: 'message',
    },
    {
      header: 'Status',
      accessorKey: 'status',
      id: 'status',
      meta: {
        width: '130px',
      },
    },
    {
      header: 'Identifier',
      accessorKey: 'identifier',
      id: 'identifier',
      meta: {
        width: '130px',
      },
    },
    {
      header: 'Log Date',
      accessorKey: 'loggedDateKey',
      id: 'loggedDateKey',
      cell: ({ row }) => row.original.loggedDateKey.toString(),
      meta: {
        width: '130px',
      },
    },
    {
      header: 'Log Timestamp',
      accessorKey: 'loggedTimestamp',
      id: 'loggedTimestamp',
      meta: {
        width: '190px',
      },
    },
    {
      header: 'View',
      id: 'view',
      cell: ({ row }) => ActionButtons(
        {
          ...{
            viewCallback: () => navigate(ROUTES.viewLogDetails, { state: row.original }),
          },
        },
      ),
      meta: {
        width: '73px',
      },
    },
  ], [navigate])

  const isLoading = isServerLogsLoading || isServiceLogNamesLoading

  return (
    <>
      <Helmet>
        <title>{TITLES.listServiceLogs}</title>
      </Helmet>
      <AppLayout isLoading={isLoading}>
        <Container className="is-fluid pt-0 pr-0 pl-0">
          <Columns multiline={false} className="mt-5">
            <Columns.Column>
              <Form.Field>
                <Label label="Select service log name" for="serviceLogName" />
                <Form.Control>
                  <div className="select is-fullwidth">
                    <select
                      id="serviceLogName"
                      data-testid="serviceLogName"
                      value={selectedServiceLogName}
                      onChange={({ target }) => { setSelectedServiceLogName(target.value) }}
                    >
                      {serviceLogNames.map((serviceLogName) => (
                        <option
                          key={serviceLogName}
                          value={serviceLogName}
                        >
                          {serviceLogName}
                        </option>
                      ))}
                    </select>
                  </div>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column size={12}>
              <Table columns={columns} data={serviceLogsResponse?.data ?? []} />
            </Columns.Column>
          </Columns>
        </Container>
      </AppLayout>
    </>
  )
}

export default ListServiceLogs
