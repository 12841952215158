const mongoClusters = [
  {
    _id: '026BEF1B-4734-425D-80A9-0A43B4FC928B',
    serverId: '05CEDF9D-1195-42D6-8318-A0FAFBC98F70',
    databaseName: 'rafatest',
    adminUsername: 'admin',
    status: 'Complete',
    providerName: 'MongoDB',
    taxonomyId: '1526',
    connectionString: 'Server=dk2dsc01pgbnc01.starwave.com:6432/postgres;Database=rafatest;Uid=admin;Pwd=<PASSWORD>;',
    state: true,
    size: 1,
    updatedAt: '2001-02-28T14:10:46Z',
    createdAt: '2001-02-28T14:10:47Z',
    createdBy: 'LUCCR005',
    updatedBy: 'LUCCR005',
    owners: ['LUCCR005'],
    datacenterName: 'MongoDB',
  },
  {
    _id: '026BEF1B-4734-425D-80A9-0A43B4FC928B',
    serverId: '05CEDF9D-1195-42D6-8318-A0FAFBC98F70',
    databaseName: 'rafatest',
    adminUsername: 'admin',
    status: 'In Progress',
    providerName: 'MongoDB',
    taxonomyId: '1526',
    connectionString: 'Server=dk2dsc01pgbnc01.starwave.com:6432/postgres;Database=rafatest;Uid=admin;Pwd=<PASSWORD>;',
    state: true,
    size: 1,
    updatedAt: '2019-02-28T14:10:46Z',
    createdAt: '2019-02-28T14:10:47Z',
    createdBy: 'LUCCR005',
    updatedBy: 'LUCCR005',
    owners: ['LUCCR005'],
    datacenterName: 'MongoDB',
  },
]

export default mongoClusters
