import classNames from 'classnames'
import React, { Ref } from 'react'
import BaseElements, { normalizeStatus } from '../../../elements'
import { IInputElementProps } from '../../../elements/types'
import { Color, Size } from '../../../types'
import { useFieldContext } from '../field/Context'

const { Input: BaseInput } = BaseElements

interface InputProps<T> {
  color?: Color;
  readOnly?: boolean;
  isStatic?: boolean;
  rounded?: boolean;
  status?: 'focus' | 'hover';
  value?: T;
  size?: Size | number;
}

type IProps<T> = Omit<IInputElementProps, 'size'> & InputProps<T>

const Input = React.forwardRef(<T extends string | boolean | number | object>({
  className,
  size,
  color,
  readOnly,
  isStatic,
  status,
  rounded,
  ...props
}: IProps<T>, ref?: Ref<HTMLInputElement>) => {
  const context = useFieldContext()
  const calculatedSize = size || context.size
  return (
    <BaseInput
      {...props}
      readOnly={readOnly || isStatic}
      className={classNames('input', className, {
        'is-static': isStatic,
        [`is-${normalizeStatus(status)}`]: status,
        'is-rounded': rounded,
        [`is-${calculatedSize}`]: typeof calculatedSize === 'string',
        [`is-${color}`]: color,
      })}
      size={typeof size === 'number' ? size : undefined}
      ref={ref}
    />
  )
})

Input.defaultProps = {
  size: undefined,
  color: undefined,
  readOnly: undefined,
  isStatic: undefined,
  status: undefined,
  value: undefined,
  rounded: undefined,
}

export default Input
