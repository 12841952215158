import { dBaaSApiConfig, NODE_ENV, openIdConfig as openId } from "../config";

const clientId = encodeURIComponent(openId.clientId);
const nonce = encodeURIComponent(openId.nonce);
const redirectUri = encodeURIComponent(window.location.origin + openId.redirectUri);
const responseType = encodeURIComponent(openId.responseType);
const scope = encodeURIComponent(openId.scope);

export const authorizationEndpoint = [
    openId.authorization,
    '?client_id=',
    clientId,
    '&nonce=',
    nonce,
    '&redirect_uri=',
    redirectUri,
    '&response_type=',
    responseType,
    '&scope=',
    scope
].join('');

const testBaseURl = `http://mockapi`

const getDBaaSBaseUrl = () => {
    if (NODE_ENV === "production") {
        return `${dBaaSApiConfig.endpoint}${dBaaSApiConfig.endpointSuffix}`
    } else if (NODE_ENV === "development") {
        return `${dBaaSApiConfig.endpointSuffix}`
    } else if (NODE_ENV === "test") {
        return `${testBaseURl}${dBaaSApiConfig.endpointSuffix}`
    }
}

const getTaxonomyUrl = () => {
    if (NODE_ENV === "production") {
        return `${dBaaSApiConfig.endpoint}${dBaaSApiConfig.taxonomyEndpointSuffix}`
    } else if (NODE_ENV === "development") {
        return `${dBaaSApiConfig.taxonomyEndpointSuffix}`
    } else if (NODE_ENV === "test") {
        return `${testBaseURl}${dBaaSApiConfig.taxonomyEndpointSuffix}`
    }
}

export const dBaaSBaseEndpoint = getDBaaSBaseUrl()
export const taxonomyBaseEndPoint = getTaxonomyUrl()