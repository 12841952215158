import classNames from 'classnames'
import React, { useMemo } from 'react'
import BaseElements from '../../../elements'
import { IInputElementProps, ILabelElementProps } from '../../../elements/types'
import { Color, Size } from '../../../types'

const { Input, Label } = BaseElements

interface IProps extends Omit<IInputElementProps, 'size' | 'type' | 'onChange'> {
  color?: Color,
  size?: Size,
  thin?: boolean,
  rounded?: boolean,
  outlined?: boolean,
  onClick?: () => void,
  onChange?: () => void,
  labelProps?: { hideLabel?: boolean } & ILabelElementProps,
}

function Switch(props: IProps) {
  const {
    id: inputId, name, color, size, thin,
    rounded, outlined, className,
    labelProps, onClick,
    onChange,
    ...rest
  } = props

  const {
    hideLabel, children, htmlFor, onClick: labelOnClick, ...restLabelProps
  } = useMemo(() => {
    if (labelProps) {
      return { ...labelProps, hideLabel: 'hideLabel' in labelProps && labelProps.hideLabel ? labelProps.hideLabel : false }
    }
    return { hideLabel: false }
  }, [labelProps])

  return (
    <>
      <Input
        id={inputId}
        type="checkbox"
        name={name}
        className={classNames('switch', {
          [`is-${color}`]: color,
          [`is-${size}`]: size,
          'is-thin': thin,
          'is-rounded': rounded,
          'is-outlined': outlined,
        }, className)}
        {...rest}
        readOnly
      />
      {!hideLabel && (
      <Label
        htmlFor={htmlFor ?? inputId}
        {...restLabelProps}
        onClick={(e) => {
          if (onClick) onClick()
          if (onChange) onChange()
          if (labelOnClick) labelOnClick(e)
        }}
      >
        {children ?? name}
      </Label>
      )}
    </>
  )
}

Switch.defaultProps = {
  color: 'success',
  size: 'normal',
  thin: undefined,
  rounded: undefined,
  outlined: undefined,
  labelProps: undefined,
  onClick: undefined,
  onChange: undefined,
}

export default Switch
