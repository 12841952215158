import ClusterOption from './type'

const tempMockInstanceSizes: ClusterOption[] = [
  {
    label: 'M10',
    value: 'M10',
    options: {
      disk: {
        storage: {
          default: 10,
          min: 10,
          max: 128,
        },
        ram: 2,
      },
      vcpu: 2,
      iops: { default: 1000 },
      additionalInfos: [
        '1500 max connections',
        'Up to 5 Gigabit network performance',
      ],
    },
  },
  {
    label: 'M20',
    value: 'M20',
    options: {
      disk: {
        storage: {
          default: 20,
          min: 10,
          max: 256,
        },
        ram: 4,
      },
      vcpu: 2,
      iops: { default: 2000 },
      additionalInfos: [
        '3000 max connections',
        'Up to 5 Gigabit network performance',
      ],
    },
  },
  {
    label: 'M30',
    value: 'M30',
    options: {
      disk: {
        storage: {
          default: 40,
          min: 10,
          max: 512,
        },
        ram: 8,
      },
      vcpu: 2,
      iops: {
        default: 3000,
        configureOptions: {
          min: 100,
          max: 1200,
        },
      },
      additionalInfos: [
        '3000 max connections',
        'Up to 10 Gigabit network performance',
      ],
    },
  },
  {
    label: 'M40',
    value: 'M40',
    options: {
      disk: {
        storage: {
          default: 80,
          min: 10,
          max: 1024,
        },
        ram: 16,
      },
      vcpu: 4,
      iops: {
        default: 3072,
        configureOptions: {
          min: 100,
          max: 3072,
        },
      },
      additionalInfos: [
        '6000 max connections',
        'Up to 10 Gigabit network performance',
      ],
    },
  },
]

export default tempMockInstanceSizes
