import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo } from 'react'
import { useUserInfo } from '../hooks/auth'
import { Notification } from './bulma'

function GlobalNotification({ children }:{children:React.ReactNode}) {
  const user = useUserInfo()
  const isUidNotAvailable = useMemo(() => {
    if (user.email && user.uid) {
      return (user.email.toLocaleLowerCase() === user.uid.toLocaleLowerCase())
    }
    return false
  }, [user])
  return (
    <>
      {isUidNotAvailable && (
      <Notification color="warning" style={{ lineHeight: '2rem', fontWeight: '500' }}>
        <p>
          <FontAwesomeIcon icon={faExclamationCircle} />
          {' '}
          You are currently logged in via your email address
          and will not be able to create databases,
          edit or delete them. An error will show during any attempt.
        </p>
        <p>
          To create, update or delete databases,
          please log out (clear browser cache) and log in using your Disney MyId / HubId.
        </p>
      </Notification>
      )}
      {children}
    </>
  )
}

export default GlobalNotification
