import classNames from 'classnames'
import React, { forwardRef } from 'react'
import BaseElements from '../elements'
import { IContainerElementProps } from '../elements/types'

import { Color } from '../types'

const { Div: Element } = BaseElements

type NotificationProps = Omit<IContainerElementProps, 'renderAs'> & {
    color?: Color;
    light?: boolean;
  }

const Notification = forwardRef<HTMLInputElement, NotificationProps>(({
  className, color, light, ...props
}, ref) => (
  <Element
    {...props}
    className={classNames(
      'notification',
      {
        [`is-${color}`]: color,
        'is-light': light,
      },
      className,
    )}
    ref={ref}
  />
))

Notification.defaultProps = {
  color: undefined,
  light: undefined,
}

export default Notification
