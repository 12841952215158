import Checkbox from './components/checkbox'
import Control from './components/control'
import Field from './components/field'
import Help from './components/help'
import Input from './components/input'
import Label from './components/label'
import Radio from './components/radio'
import Select from './components/select'
import Switch from './components/switch'
import TextArea from './components/textarea'

interface FormType {
    Field: typeof Field,
    Control: typeof Control
    Help: typeof Help,
    Input: typeof Input,
    Label: typeof Label,
    Radio: typeof Radio,
    Select: typeof Select,
    Checkbox: typeof Checkbox,
    Switch: typeof Switch,
    TextArea: typeof TextArea
}

const Form: FormType = {
  Field,
  Control,
  Help,
  Input,
  Label,
  Radio,
  Select,
  Checkbox,
  Switch,
  TextArea,
}

export default Form
