import classNames from 'classnames'
import React from 'react'
import BaseElements, { normalizeStatus } from '../../../elements'
import { ITextAreaElementProps } from '../../../elements/types'
import { Color, Size } from '../../../types'
import { useFieldContext } from '../field/Context'

interface TextareaProps extends ITextAreaElementProps {
    size?: Size;
    color?: Color;
    fixedSize?: boolean;
    status?: 'active' | 'focus' |'hover' | string;
  }

const { TextArea: Element } = BaseElements

const TextArea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({
  className, size, color, status, fixedSize, ...props
}, ref) => {
  const context = useFieldContext()
  const calculatedSize = size || context.size
  return (
    <Element
      {...props}
      className={classNames('textarea', className, {
        [`is-${normalizeStatus(status)}`]: status,
        'has-fixed-size': fixedSize,
        [`is-${calculatedSize}`]: typeof calculatedSize === 'string',
        [`is-${color}`]: color,
      })}
      ref={ref}
    />
  )
})

TextArea.defaultProps = {
  size: undefined,
  color: undefined,
  fixedSize: undefined,
  status: undefined,
}

export default TextArea
