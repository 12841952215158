import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import { ColumnDef } from '@tanstack/table-core'
import { ActionButtons, Table } from '../../components'
import AppLayout from '../../layouts'
import { useFetchEvents } from '../../hooks/api/events'
import { TITLES } from '../../constants'
import { Columns, Container } from '../../components/bulma'
import { TEventPreview } from '../../types/events'

function ListEvents() {
  const navigate = useNavigate()

  const { pathname } = useLocation()

  const { data: eventsResponse, isFetching: isLoading } = useFetchEvents()

  const columns: ColumnDef<TEventPreview>[] = React.useMemo(() => [
    {
      header: 'Owners',
      accessorKey: 'owners',
      id: 'owners',
    },
    {
      header: 'Event Type',
      accessorKey: 'eventType',
      id: 'eventType',
    },
    {
      header: 'Database Name',
      accessorKey: 'databaseName',
      id: 'databaseName',
    },
    {
      header: 'User',
      accessorKey: 'createdBy',
      id: 'createdBy',
      meta: {
        width: '130px',
      },
    },
    {
      header: 'Taxonomy Id',
      accessorKey: 'taxonomyId',
      id: 'taxonomyId',
      cell: ({ row }) => row.original.taxonomyId.toString(),
      meta: { width: '123px' },
    },
    {
      header: 'Database ID',
      accessorKey: 'dbaasDatabase',
      id: 'dbaasDatabase',
    },
    {
      header: 'View',
      id: 'view',
      cell: ({ row }) => ActionButtons(
        {
          ...{
            viewCallback: () => navigate(`${pathname}/${row.original._id}/view`),
          },
        },
      ),
      meta: {
        width: '73px',
      },
    },
  ], [navigate, pathname])

  return (
    <>
      <Helmet>
        <title>{TITLES.listEvents}</title>
      </Helmet>
      <AppLayout isLoading={isLoading}>
        <Container className="is-fluid pr-0 pl-0">
          <Columns>
            <Columns.Column>
              <Table columns={columns} data={eventsResponse?.data ?? []} />
            </Columns.Column>
          </Columns>
        </Container>
      </AppLayout>
    </>
  )
}

export default ListEvents
