import { useMutation, useQueryClient } from 'react-query'
import { useDBaaSClient } from '../../client'

const useUpdateDatabaseOwners = () => {
  const client = useDBaaSClient()
  const queryClient = useQueryClient()

  const addOwner = ({ databaseID, owner }: { databaseID: string, owner: string }) => client.post(`/databases/${databaseID}/owners`, {
    owners: [owner],
  })

  const removeOwner = ({ databaseID, owner }: { databaseID: string, owner: string }) => client.put(`/databases/${databaseID}/owners`, {
    owners: [owner],
  })

  return useMutation(({
    databaseID,
    owner, remove,
  }: { databaseID: string, owner: string, remove: boolean }) => {
    if (remove) {
      return removeOwner({ databaseID, owner })
    }
    return addOwner({ databaseID, owner })
  }, {
    mutationKey: ['database', 'owner', 'edit'],
    onSuccess: (_, v) => {
      queryClient.invalidateQueries(['databases', v.databaseID])
    },
  })
}

export default useUpdateDatabaseOwners
