import classNames from 'classnames'
import React, { forwardRef } from 'react'
import BaseElements, { normalizeStatus } from '../../../elements'
import { ISelectElementProps } from '../../../elements/types'
import { useFieldContext } from '../field/Context'
import { Size } from '../field/types'

const { Select: SelectBase, Label } = BaseElements

type ISelectProps = Omit<ISelectElementProps, 'size'> & {
  rounded?: boolean,
  loading?: boolean,
  fullwidth?: boolean,
  status?: 'active' | 'focus' | 'hover' | string,
  size?: Size | number
}

const Select = forwardRef<HTMLSelectElement, ISelectProps>(({
  className,
  rounded,
  style,
  size,
  color,
  loading,
  status,
  disabled,
  value,
  multiple,
  children,
  name,
  fullwidth,
  ...props
}, ref) => {
  const context = useFieldContext()
  const calculatedSize = size || context.size
  return (
    <Label
      className={classNames('select', className, {
        [`is-${calculatedSize}`]: typeof calculatedSize === 'string',
        [`is-${color}`]: color,
        'is-fullwidth': fullwidth,
        'is-loading': loading,
        'is-multiple': multiple,
        'is-rounded': rounded,
      })}
      style={style}
    >
      <SelectBase
        {...props}
        className={classNames({
          [`is-${normalizeStatus(status)}`]: status,
        })}
        multiple={multiple}
        value={value}
        disabled={disabled}
        name={name}
        size={typeof size === 'number' ? size : undefined}
        ref={ref}
      >
        {children}
      </SelectBase>
    </Label>
  )
})

Select.defaultProps = {
  rounded: undefined,
  loading: undefined,
  fullwidth: undefined,
  status: undefined,
  size: undefined,
}

export default Select
