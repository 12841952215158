import React, { useEffect, useRef, useState } from 'react'
import { Label, Panel, PanelBody } from '../../../components'
import { Button, Form } from '../../../components/bulma'

interface IProps {
    onChange?: (id: string) => void
}

const tempProjectsValues = [
  'Disneyverse',
  'Voice Disney',
  'Disneyzilla',
]

function Project(props: IProps) {
  const { onChange } = props

  const [selectionValue, setSelectionValue] = useState<'createPrj' | 'selectPrj'>()

  const [project, setProject] = useState('')

  const [selectedProject, setSelectedProject] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const [projects, setProjects] = useState(tempProjectsValues)

  const onChangeRef = useRef(onChange)

  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])

  useEffect(() => {
    if (onChangeRef.current) { onChangeRef.current(selectedProject) }
  }, [selectedProject])

  return (
    <Panel className="mt-5" titleClassName="" title={selectedProject ? `Selected project: ${selectedProject}` : undefined}>
      <PanelBody>
        <Panel title="Project">
          <PanelBody>
            <Form.Control>
              <Form.Radio
                checked={selectionValue === 'createPrj'}
                onChange={() => { setSelectionValue('createPrj') }}
                value="createPrj"
                className="mr-3"
              >
                Create project
              </Form.Radio>
              <Form.Radio
                checked={selectionValue === 'selectPrj'}
                onChange={() => { setSelectionValue('selectPrj') }}
                value="selectPrj"
              >
                Select project
              </Form.Radio>
            </Form.Control>
            {selectionValue === 'selectPrj' && (
            <Form.Field>
              <Label label="Projects" for="selectProject" required />
              <Form.Control>
                <Form.Select
                  value={selectedProject ?? ''}
                  // defaultValue=""
                  fullwidth
                  id="selectProject"
                  name="selectProject"
                  onChange={(e) => {
                    setSelectedProject(e.target.value)
                  }}
                >
                  <option value="" disabled>Select Project</option>
                  {projects.map((p, i) => <option key={`${p + i}`} value={p}>{p}</option>)}
                </Form.Select>
              </Form.Control>
            </Form.Field>
            )}
            {selectionValue === 'createPrj' && (
            <>
              <Form.Field>
                <Label label="Project Name" for="selectProject" required />
                <Form.Control>
                  <Form.Input
                    name="selectProject"
                    id="selectProject"
                    disabled={isLoading}
                    value={project}
                    onChange={(e) => { setProject(e.target.value.trim()) }}
                  />
                </Form.Control>
              </Form.Field>
              <Form.Field display="flex" mt={5} flexDirection="row-reverse">
                <Button
                  disabled={isLoading || (project.trim()).length === 0}
                  color="success"
                  type="submit"
                  onClick={() => {
                    setIsLoading(true)
                    setTimeout(() => {
                      setIsLoading(false)
                      setProjects((currentProjects) => {
                        const newArray = [...currentProjects]
                        if (project) {
                          newArray.push(project)
                        }
                        return newArray
                      })
                      setSelectedProject(project)
                      setSelectionValue('selectPrj')
                      setProject('')
                    }, 3000)
                  }}
                >
                  Submit
                </Button>
              </Form.Field>
            </>
            )}
          </PanelBody>
        </Panel>
      </PanelBody>
    </Panel>
  )
}

Project.defaultProps = {
  onChange: undefined,
}

export default Project
