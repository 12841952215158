import React from 'react'
import { Notification } from '../bulma'

function SuccessBannerNotification({
  message,
  dismissButtonCallback,
}: { message: string, dismissButtonCallback?: () => void }) {
  return (
    <Notification color="success">
      {
        dismissButtonCallback && (
          <button
            data-testid="nCrossBtnId"
            type="button"
            className="delete"
            onClick={dismissButtonCallback}
          >
            &nbsp;
          </button>
        )
      }
      <span>{message}</span>
    </Notification>
  )
}

SuccessBannerNotification.defaultProps = {
  dismissButtonCallback: undefined,
}

export default SuccessBannerNotification
