import { useMutation, useQueryClient } from 'react-query'
import { useDBaaSClient } from '../../client'

interface ICreateAdministrator {
    name: string
}

const useCreateAdministrator = () => {
  const { post } = useDBaaSClient()

  const queryClient = useQueryClient()

  return useMutation((body: ICreateAdministrator) => post('/admins', body), {
    onSuccess: () => {
      queryClient.invalidateQueries('admins')
    },
  })
}

export default useCreateAdministrator
