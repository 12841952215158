import classNames from 'classnames'
import React from 'react'
import BaseElements from '../elements'
import { IArticleElementProps } from '../elements/types'
import { Color, Size } from '../types'

const { Article } = BaseElements

type IProps = Omit<IArticleElementProps, 'renderAs'> & {
    size?: Size;
    color?: Color;
}

function Message({
  children, className, color, size, ...props
}: IProps) {
  return (
    <Article
      {...props}
      className={classNames('message', className, {
        [`is-${color}`]: color,
        [`is-${size}`]: size,
      })}
    >
      {children}
    </Article>
  )
}

Message.defaultProps = {
  size: undefined,
  color: undefined,
}

export default Message
