import moment from 'moment'
import React, {
  memo,
  useEffect,
  useMemo, useRef, useState,
} from 'react'
import { useLocation } from 'react-router-dom'

const MaintenanceContext = React.createContext<{
  isLoading: boolean,
  isUnderMaintenance: boolean,
  updateMaintenanceStatus:(p: { isLoading: boolean, isUnderMaintenance: boolean }) => void
    }>({
      isLoading: false,
      isUnderMaintenance: false,
      updateMaintenanceStatus: () => { /* */ },
    })

interface IProps {
  children: React.ReactNode
}

function MaintenanceProvider({ children }: IProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false)

  const location = useLocation()

  const updateMaintenanceStatusRef = useRef((p: {
    isLoading: boolean,
    isUnderMaintenance: boolean
  }) => {
    setIsLoading(p.isLoading)
    setIsUnderMaintenance(p.isUnderMaintenance)
  })

  const value = useMemo(() => ({
    isLoading,
    isUnderMaintenance,
    updateMaintenanceStatus: updateMaintenanceStatusRef.current,
  }), [isLoading, isUnderMaintenance])

  const locationRef = useRef(location)

  const isUnderMaintenanceFromSource = useMemo(() => {
    try {
      const data = JSON.parse(localStorage.getItem('maintenance') ?? '')
      if (data && 'date' in data && data.date) {
        const date = moment(data.date)
        if (date.isValid() && date.isAfter(moment())) {
          return true
        }
        return false
      }
      return false
    } catch (error) {
      return false
    }
  }, [])

  useEffect(() => {
    if (isUnderMaintenanceFromSource) {
      const isByPassEnabled = localStorage.getItem('isByPassEnabled')
      if (isByPassEnabled === 'true') {
        setIsUnderMaintenance(false)
        return
      }
      if (locationRef.current.pathname.substring(0, 9) === '/override'
      ) {
        setIsUnderMaintenance(false)
        localStorage.setItem('isByPassEnabled', 'true')
        return
      }
      setIsUnderMaintenance(true)
    }
  }, [isUnderMaintenanceFromSource])

  return (
    <MaintenanceContext.Provider value={value}>
      {children}
    </MaintenanceContext.Provider>
  )
}

export { MaintenanceContext }

export default memo(MaintenanceProvider)
