import { useMutation, useQueryClient } from 'react-query'
import { useDBaaSClient } from '../../client'

const useUpdateDatabaseTaxonomy = () => {
  const client = useDBaaSClient()
  const queryClient = useQueryClient()

  return useMutation(({ databaseID, selectedTaxonomyId }:{databaseID:string, selectedTaxonomyId:string}) => client.put(`/databases/${databaseID}/taxonomy`, {
    taxonomyId: selectedTaxonomyId,
  }), {
    mutationKey: ['database', 'taxonomy', 'edit'],
    onSuccess: (_, v) => {
      queryClient.invalidateQueries(['databases', v.databaseID])
    },
  })
}

export default useUpdateDatabaseTaxonomy
