import React from 'react'
import classNames from 'classnames'
import BaseElements from '../../../elements'
import {
  IContainerElementProps,
} from '../../../elements/types'
import { useFieldContext } from './Context'
import { Size } from './types'

const { Div } = BaseElements

const FieldLabel = React.forwardRef<HTMLDivElement, Omit<IContainerElementProps, 'renderAs'> & {size?: Size}>(({
  children, className, size, ...props
}, ref) => {
  const context = useFieldContext()
  return (
    <Div
      {...props}
      className={classNames('field-label', className, {
        [`is-${size}`]: size || context.size,
      })}
      ref={ref}
    >
      {children}
    </Div>
  )
})

FieldLabel.defaultProps = {
  size: undefined,
}

export default FieldLabel
