import TagGroup from './components/TagGroup'
import TagInternal from './Tag'

type TagInternalType = typeof TagInternal

interface TagType extends TagInternalType {
    groupTag: typeof TagGroup
}

const Tag = TagInternal as TagType

Tag.groupTag = TagGroup

export default Tag
