import FieldInternal from './Field'
import FieldBody from './FieldBody'
import FieldLabel from './FieldLabel'

type FieldInternalType = typeof FieldInternal

interface FieldType extends FieldInternalType {
    Label: typeof FieldLabel,
    Body: typeof FieldBody
}

const Field = FieldInternal as FieldType

Field.Body = FieldBody

Field.Label = FieldLabel

export default Field
