import { useQuery } from 'react-query'
import { useDBaaSClient } from '../../client'

interface IStat {
  statType: 'storage' | string,
  dbaasDatabase: string,
  total_size_mb: number | string,
  row_size_mb: number | string,
  log_size_mb: number | string,
  timestamp: string,
}

const useFetchDatabaseStats = (databaseID?: string, options?: { enabled: boolean }) => {
  const { get } = useDBaaSClient()
  return useQuery(['databases', databaseID, 'stats'], () => get<IStat[]>(`/databases/${databaseID}/stats`), options)
}

export default useFetchDatabaseStats
