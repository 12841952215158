import React from 'react'
import AuthProvider from './AuthProvider'
import CredentialProvider from './CredentialProvider/CredentialProvider'
import MaintenanceProvider from './MaintenanceProvider'
import DBaaSClientProvider from './DBaaSClientProvider/DBaaSClientProvider'

function AppProvidersWrapper({ children }: { children: React.ReactNode }) {
  return (
    <AuthProvider>
      <CredentialProvider>
        <DBaaSClientProvider>
          <MaintenanceProvider>
            {children}
          </MaintenanceProvider>
        </DBaaSClientProvider>
      </CredentialProvider>
    </AuthProvider>
  )
}

export default AppProvidersWrapper
