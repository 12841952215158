import classNames from 'classnames'
import React from 'react'
import BaseElements from '../elements'
import { Breakpoint, IContainerElementProps } from '../elements/types'

const { Div } = BaseElements

type IProps = Omit<IContainerElementProps, 'renderAs'> & {
    max?: boolean;
    breakpoint?: Breakpoint | 'fluid';
}

const Container = React.forwardRef<HTMLDivElement, IProps>(({
  children, max, breakpoint, className, ...props
}, ref) => {
  const canSetMax = ['desktop', 'widescreen'].includes(breakpoint ?? '')
  return (
    <Div
      {...props}
      className={classNames('container', className, {
        [`is-${canSetMax && max ? 'max-' : ''}${breakpoint}`]: breakpoint,
      })}
      ref={ref}
    >
      {children}
    </Div>
  )
})

Container.defaultProps = {
  max: undefined,
  breakpoint: undefined,
}

export default Container
