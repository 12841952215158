import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { useDBaaSClient } from '../../client'

const useFetchProviders = (
  dataCenter: string,
  options?: { onSuccess?: ((data: AxiosResponse<string[]>) => void) | undefined },
) => {
  const { get } = useDBaaSClient()

  return useQuery(['datacenters', dataCenter, 'providers'], () => get<string[]>(`/datacenters/${dataCenter}/providers`), {
    onSuccess: options?.onSuccess,
    enabled: dataCenter.length > 0,
  })
}

export default useFetchProviders
