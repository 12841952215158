import classNames from 'classnames'
import React, { forwardRef } from 'react'
import { ContainerElement } from '../elements'
import { IContainerElementProps } from '../elements/types'
import { Color, Size } from '../types'

interface TagProps {
    color?: Color,
    size?: Size,
    rounded?: boolean,
    remove?: boolean
}

type IProps = TagProps & Omit<IContainerElementProps, 'renderAs' | 'innerRef'>

const Tag = forwardRef<HTMLSpanElement, IProps>((props, ref) => {
  const {
    children, color, size, remove, rounded, className, ...rest
  } = props
  return (
    <ContainerElement
      renderAs="span"
      {...rest}
      className={classNames('tag', className, {
        [`is-${size}`]: size,
        [`is-${color}`]: color,
        'is-rounded': rounded,
        'is-delete': remove,
      })}
      innerRef={ref}
    >
      {!remove && children}
    </ContainerElement>
  )
})

Tag.defaultProps = {
  color: undefined,
  size: undefined,
  rounded: undefined,
  remove: undefined,
}

export default Tag
