import { useQuery } from 'react-query'
import ITaxonomyDetails from '../../../types/taxonomy'
import { useTaxonomyClient } from '../../client'

const useFetchTaxonomyDetailsById = (taxonomyId?: string, options?: { enabled: boolean }) => {
  const { get } = useTaxonomyClient()

  return useQuery(['detail', taxonomyId], () => get<ITaxonomyDetails>('/detail', { params: { id: taxonomyId } }), options)
}

export default useFetchTaxonomyDetailsById
