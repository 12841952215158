import axios, { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import IErrorResponse from '../../../types/error/api'
import { useDBaaSClient } from '../../client'

const useDeleteAdministratorById = () => {
  const client = useDBaaSClient()
  const queryClient = useQueryClient()

  const queryResult = useMutation((adminId: string) => client.delete(`/admins/${adminId}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('admins')
    },
  })

  const error = useMemo(() => {
    const defaultErrorMessage = `Unexpected error occurred while deleting${queryResult.variables ? ` ${queryResult.variables}` : ''} admin user!. Please try again or contact DBaaS support`
    if (queryResult.isError) {
      if (queryResult.error && axios.isAxiosError(queryResult.error)) {
        const errRes = queryResult.error as AxiosError<IErrorResponse>
        if (errRes.response?.data) {
          return { message: errRes.response.data.message }
        }
        return { message: defaultErrorMessage }
      }
      return Error(defaultErrorMessage)
    }
    return null
  }, [queryResult.error, queryResult.isError, queryResult.variables])

  return { ...queryResult, error }
}

export default useDeleteAdministratorById
