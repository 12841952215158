import MessageBody from './components/body'
import MessageHeader from './components/header'
import InternalMessage from './Message'

type InternalMessageType = typeof InternalMessage

interface MessageType extends InternalMessageType {
    Header: typeof MessageHeader,
    Body: typeof MessageBody
}

const Message = InternalMessage as MessageType
Message.Header = MessageHeader
Message.Body = MessageBody

export default Message
