import React from 'react'
import classNames from 'classnames'
import BaseElements from '../../../elements'
import {
  IContainerElementProps,
} from '../../../elements/types'

const { Div } = BaseElements

const FieldBody = React.forwardRef<HTMLDivElement, Omit<IContainerElementProps, 'renderAs'>>(({ children, className, ...props }, ref) => (
  <Div
    {...props}
    className={classNames('field-body', className)}
    ref={ref}
  >
    {children}
  </Div>
))

export default FieldBody
