import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { NestedValue, useForm, Resolver } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { Label, Panel, PanelBody } from '../../../components'
import { Button, Form, Message } from '../../../components/bulma'
import tempMockInstanceSizes from './mockData'
import Organization from './organization'
import Project from './project'
import ClusterOption from './type'

interface IMongoDBFormFields {
  clusterName: string,
  instanceSize: NestedValue<ClusterOption>,
  diskSize: number,
  numberOfElectableNodes: number,
  numberOfReadOnlyNodes: number,
  mongoDbVersion: string,
  backupRequired: boolean,
  clusterRegion: string,
  organization: string,
  project: string,
  privateEndpoint: boolean,
  awsRegion: string,
  awsVpcId: string,
  awsSubnetId: string,
  awsSecurityGroupId: string,
}

const instanceSizeYupObject = yup.object().shape({
  label: yup.string(),
  value: yup.string(),
  options: yup.object().shape({
    disk: yup.object().shape({
      storage: yup.object().shape({
        default: yup.number(),
        min: yup.number(),
        max: yup.number(),
      }),
      ram: yup.number(),
    }),
    vcpu: yup.number(),
    iops: yup.object({
      default: yup.number(),
      configureOptions: yup.object({
        min: yup.number(),
        max: yup.number(),
      }),
    }).default(null)
      .nullable(),
    additionalInfos: yup.array().of(yup.string()),
  }),
})

const schema = yup.object({
  clusterName: yup.string().required('is required')
    .matches(/^[a-zA-Z0-9_]+$/, { message: 'Must only contain alphanumeric characters. Underscore is also allowed' })
    .min(1)
    .max(64, 'Must be shorter than 64 characters'),
  orgId: yup.string().required('is required'),
  instanceSize: instanceSizeYupObject.required('Instance size must be selected').default(undefined),
  diskSize: yup.number().required('is required')
    .nullable().typeError('Storage is must be a number')
    .test(
      'disk-size-instanceSize-storage-min-max',
      (value, { parent, createError, path }) => {
        if (parent.instanceSize == null) {
          return createError({ path, message: 'Instance size must be selected' })
        }
        if (value == null) {
          return createError({ path, message: 'Disk Size cannot be empty' })
        }
        const instanceSize = parent.instanceSize as ClusterOption
        const { min, max } = instanceSize.options.disk.storage
        if (value < min) {
          return createError({ path, message: `Minimum disk size ${min}` })
        }
        if (value > max) {
          return createError({ path, message: `Maximum disk size ${max}` })
        }
        return true
      },
    ),
  numberOfElectableNodes: yup.number().required().typeError('Electable node must be selected').min(0)
    .max(9)
    .default(0),
  numberOfReadOnlyNodes: yup.number().required().typeError('Read only node must be selected').min(0)
    .max(9)
    .default(0),
  mongoDbVersion: yup.string().required('is required'),
  backupRequired: yup.boolean(),
  clusterRegion: yup.string().required('is required'),
  organization: yup.string().required('is required'),
  project: yup.string().required('is required'),
  privateEndpoint: yup.boolean(),
  awsRegion: yup.string(),
  awsVpcId: yup.string(),
  awsSubnetId: yup.string(),
  awsSecurityGroupId: yup.string(),
})

function CreateMongoDatabaseForm() {
  const {
    formState: { errors }, register, setValue, watch,
    handleSubmit,
  } = useForm<IMongoDBFormFields>({
    resolver: yupResolver(schema) as Resolver<IMongoDBFormFields>,
    mode: 'onChange',
    defaultValues: {
      clusterRegion: 'us-west-2',
      backupRequired: false,
      privateEndpoint: false,
      awsRegion: 'us-west-2',
    },
  })

  const navigation = useNavigate()

  const values = watch()

  return (
    <>
      <Organization onChange={(value) => { setValue('organization', value) }} />
      <Project onChange={(value) => { setValue('project', value) }} />
      <Panel className="mt-5" titleClassName="">
        <PanelBody>
          <Panel title="Cluster">
            <PanelBody>
              <Form.Field>
                <Label label="Region" for="instanceSize" required />
                <Form.Control>
                  <Form.Select
                    fullwidth
                    id="clusterRegion"
                    {...register('clusterRegion')}
                    color={errors.clusterRegion ? 'danger' : undefined}
                    value={values.clusterRegion ?? ''}
                  >
                    <option value="us-west-2">us-west-2</option>
                  </Form.Select>
                </Form.Control>
              </Form.Field>

              <Form.Field>
                <Label label="Instance size" for="instanceSize" required />
                <Form.Control>
                  <Form.Select
                    fullwidth
                    id="instanceSize"
                    value={values.instanceSize ? values.instanceSize.label : ''}
                    onChange={(e) => {
                      const value = tempMockInstanceSizes.find((v) => e.target.value === v.label)
                      if (value) {
                        setValue('instanceSize', value, { shouldValidate: true })
                      }
                    }}
                    color={errors.instanceSize != null ? 'danger' : ''}
                  >
                    <option value="" disabled>Select instance size</option>
                    {tempMockInstanceSizes.map((p, i) => <option key={`${p.value + i}`} value={p.value}>{p.label}</option>)}
                  </Form.Select>
                  <Form.Help color="danger">
                    {errors.instanceSize != null ? errors.instanceSize.message : ''}
                  </Form.Help>
                </Form.Control>
              </Form.Field>

              {values.instanceSize && (
              <Message>
                <Message.Body>
                  <span>
                    <span className="title is-6"> RAM : </span>
                    {values.instanceSize.options.disk.ram}
                    {', '}
                  </span>
                  <span>
                    <span className="title is-6"> vCPU :</span>
                    {' '}
                    {values.instanceSize.options.vcpu}
                    {', '}
                  </span>
                  <span>
                    <span className="title is-6"> IOPS :</span>
                    {' '}
                    {values.instanceSize.options.iops.default}
                    {', '}
                  </span>
                  <span>
                    <span className="title is-6"> Additional Info :</span>
                    {' '}
                    {values.instanceSize.options.additionalInfos.map((info, i) => {
                      const separator = (values.instanceSize.options.additionalInfos.length - 1) === i ? '.' : ' | '
                      return info + separator
                    })}
                  </span>
                </Message.Body>

              </Message>
              )}

              <Form.Field>
                <Label label="Disk size" for="diskSize" required />
                <Form.Control>
                  <Form.Input
                    id="diskSize"
                    {...register('diskSize')}
                    color={errors.diskSize ? 'danger' : ''}
                    value={values.diskSize ?? ''}
                  />
                </Form.Control>
                <Form.Help color="danger">{ errors.diskSize != null ? errors.diskSize.message : ''}</Form.Help>
              </Form.Field>
              <Form.Field>
                <Label label="Cluster Name" required for="clusterName" />
                <Form.Control>
                  <Form.Input
                    id="clusterName"
                    {...register('clusterName')}
                    color={errors.clusterName ? 'danger' : ''}
                    value={values.clusterName ?? ''}
                  />
                </Form.Control>
                <Form.Help color="danger">{ errors.clusterName != null ? errors.clusterName.message : ''}</Form.Help>
              </Form.Field>

              <Form.Field>
                <Label label="Electable Nodes" for="numberOfElectableNodes" required />
                <Form.Control>
                  <Form.Input
                    id="numberOfElectableNodes"
                    {...register('numberOfElectableNodes')}
                    color={errors.diskSize ? 'danger' : ''}
                    value={values.numberOfElectableNodes ?? ''}
                  />
                </Form.Control>
                <Form.Help color="danger">{ errors.numberOfElectableNodes != null ? errors.numberOfElectableNodes.message : ''}</Form.Help>
              </Form.Field>

              <Form.Field>
                <Label label="ReadOnly Nodes" for="numberOfReadOnlyNodes" required />
                <Form.Control>
                  <Form.Input
                    id="numberOfReadOnlyNodes"
                    {...register('numberOfReadOnlyNodes')}
                    color={errors.diskSize ? 'danger' : undefined}
                    value={values.numberOfReadOnlyNodes ?? ''}
                  />
                </Form.Control>
                <Form.Help color="danger">{ errors.numberOfReadOnlyNodes != null ? errors.numberOfReadOnlyNodes.message : ''}</Form.Help>
              </Form.Field>

              <Form.Field>
                <Label label="MongoDb Version" for="mongoDbVersion" required />
                <Form.Control>
                  <Form.Select
                    fullwidth
                    id="mongoDbVersion"
                    {...register('mongoDbVersion')}
                    color={errors.mongoDbVersion ? 'danger' : undefined}
                    value={values.mongoDbVersion ?? ''}

                  >
                    <option value="" disabled>Select MongoDb Version</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </Form.Select>
                </Form.Control>
                <Form.Help color="danger">{ errors.mongoDbVersion != null ? errors.mongoDbVersion.message : ''}</Form.Help>
              </Form.Field>

              <Form.Field horizontal mt={5} alignItems="baseline">
                <Form.Field.Label textAlign="left" className="mr-0 title is-6">Backup </Form.Field.Label>
                <Form.Field.Body>
                  <Form.Field>
                    <Form.Control>
                      <Form.Checkbox
                        {...register('backupRequired')}
                        onChange={(e) => {
                          setValue('backupRequired', e.target.checked)
                        }}
                        checked={values.backupRequired ?? false}
                      />
                    </Form.Control>
                  </Form.Field>
                </Form.Field.Body>
              </Form.Field>

              <Form.Field horizontal mt={5} alignItems="baseline">
                <Form.Field.Label textAlign="left" className="mr-0 title is-6">Private endpoint </Form.Field.Label>
                <Form.Field.Body>
                  <Form.Field>
                    <Form.Control>
                      <Form.Checkbox
                        {...register('privateEndpoint')}
                        onChange={(e) => {
                          setValue('privateEndpoint', e.target.checked)
                        }}
                        checked={values.privateEndpoint ?? false}
                      />
                    </Form.Control>
                  </Form.Field>
                </Form.Field.Body>
              </Form.Field>

              {values.privateEndpoint && (
              <>
                <Form.Field>
                  <Label label="AWS Region" for="awsRegion" required />
                  <Form.Control>
                    <Form.Select
                      fullwidth
                      id="awsRegion"
                      {...register('awsRegion')}
                      color={errors.awsRegion ? 'danger' : undefined}
                      value={values.awsRegion ?? ''}
                    >
                      <option value="us-west-2">us-west-2</option>
                    </Form.Select>
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Label label="Aws Vpc Id" for="awsVpcId" required />
                  <Form.Control>
                    <Form.Input
                      id="awsVpcId"
                      {...register('awsVpcId')}
                      color={errors.awsVpcId ? 'danger' : undefined}
                      value={values.awsVpcId ?? ''}
                    />
                  </Form.Control>
                  <Form.Help color="danger">{ errors.awsVpcId != null ? errors.awsVpcId.message : ''}</Form.Help>
                </Form.Field>

                <Form.Field>
                  <Label label="Aws Subnet Id" for="awsSubnetId" required />
                  <Form.Control>
                    <Form.Input
                      id="awsSubnetId"
                      {...register('awsSubnetId')}
                      color={errors.awsSubnetId ? 'danger' : undefined}
                      value={values.awsSubnetId ?? ''}
                    />
                  </Form.Control>
                  <Form.Help color="danger">{ errors.awsSubnetId != null ? errors.awsSubnetId.message : ''}</Form.Help>
                </Form.Field>

                <Form.Field>
                  <Label label="Aws Security Group Id" for="awsSecurityGroupId" required />
                  <Form.Control>
                    <Form.Input
                      id="awsSecurityGroupId"
                      {...register('awsSecurityGroupId')}
                      color={errors.awsSecurityGroupId ? 'danger' : undefined}
                      value={values.awsSecurityGroupId ?? ''}
                    />
                  </Form.Control>
                  <Form.Help color="danger">{ errors.awsSecurityGroupId != null ? errors.awsSecurityGroupId.message : ''}</Form.Help>
                </Form.Field>
              </>
              )}

            </PanelBody>
          </Panel>
        </PanelBody>
      </Panel>

      <Form.Field className="is-flex is-justify-content-space-between mt-5">
        <Button color="danger" onClick={() => { navigation(-1) }}>Cancel</Button>
        <Button
          disabled={false}
          color="success"
          type="submit"
          onClick={() => {
            const instanceSizeValue = values.instanceSize ? values.instanceSize.value : ''
            // eslint-disable-next-line no-console
            console.log({
              ...values,
              instanceSize: instanceSizeValue,
            })
            handleSubmit(() => {
              //
            })()
          }}
        >
          Submit
        </Button>
      </Form.Field>
    </>
  )
}

export default CreateMongoDatabaseForm
