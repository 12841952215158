// CREATE
const createDatabaseName = 'database.create'
const createDatabaseLabel = 'Database Created'
const createDatabaseErrorName = `${createDatabaseName}_error`
const createDatabaseErrorLabel = `${createDatabaseLabel} Error`

const createAdminName = 'admin.create'
const createAdminLabel = 'Administrator Created'
const createAdminErrorName = `${createAdminName}_error`
const createAdminErrorLabel = `${createAdminLabel} Error`

const createServerConfigurationName = 'serverconfig.create'
const createServerConfigurationLabel = 'Server Configuration Created'
const createServerConfigurationErrorName = `${createServerConfigurationName}_error`
const createServerConfigurationErrorLabel = `${createServerConfigurationLabel} Error`

// DELETE
const deleteDatabaseName = 'database.delete'
const deleteDatabaseLabel = 'Database Deleted'
const deleteDatabaseErrorName = `${deleteDatabaseName}_error`
const deleteDatabaseErrorLabel = `${deleteDatabaseLabel} Error`

const deleteAdminName = 'admin.delete'
const deleteAdminLabel = 'Administrator Deleted'
const deleteAdminErrorName = `${deleteAdminName}_error`
const deleteAdminErrorLabel = `${deleteAdminLabel} Error`

const deleteServerName = 'serverconfig.delete'
const deleteServerLabel = 'Server Configuration Deleted'
const deleteServerErrorName = `${deleteServerName}_error`
const deleteServerErrorLabel = `${deleteServerLabel} Error`

// EDIT
const editDbPasswordName = 'database.edit'
const editDbPasswordLabel = 'Database Edited'
const editDbPasswordErrorName = `${editDbPasswordName}_error`
const editDbPasswordErrorLabel = `${editDbPasswordLabel} - Error`

const editAdminName = 'admin.edit'
const editAdminLabel = 'Administrator Edited'
const editAdminErrorName = `${editAdminName}_error`
const editAdminErrorLabel = `${editAdminLabel} - Error`

const editServerName = 'serverconfig.edit'
const editServerLabel = 'Server Configuration Edited'
const editServerErrorName = `${editServerName}_error`
const editServerErrorLabel = `${editServerLabel} - Error`

export default {
  createDatabaseName,
  createDatabaseLabel,
  createDatabaseErrorName,
  createDatabaseErrorLabel,
  createServerConfigurationName,
  createServerConfigurationLabel,
  createServerConfigurationErrorName,
  createServerConfigurationErrorLabel,
  createAdminName,
  createAdminLabel,
  createAdminErrorName,
  createAdminErrorLabel,
  deleteDatabaseName,
  deleteDatabaseLabel,
  deleteDatabaseErrorName,
  deleteDatabaseErrorLabel,
  deleteAdminName,
  deleteAdminLabel,
  deleteAdminErrorName,
  deleteAdminErrorLabel,
  deleteServerName,
  deleteServerLabel,
  deleteServerErrorName,
  deleteServerErrorLabel,
  editDbPasswordName,
  editDbPasswordLabel,
  editDbPasswordErrorName,
  editDbPasswordErrorLabel,
  editAdminName,
  editAdminLabel,
  editAdminErrorName,
  editAdminErrorLabel,
  editServerName,
  editServerLabel,
  editServerErrorName,
  editServerErrorLabel,
}
