import { useQuery } from 'react-query'
import { IEvent } from '../../../types/events'
import { useDBaaSClient } from '../../client'
import { useFetchIsAdmin } from '../admin'

const useFetchEventById = (id?: string) => {
  const { get } = useDBaaSClient()

  const { isFetching: isLoading, isSuccess } = useFetchIsAdmin()
  const canFetch = !isLoading && isSuccess

  const query = useQuery(['events', id], () => get<IEvent | undefined>(`/events/${id}`), { enabled: canFetch && id != null })

  return { ...query, isFetching: isLoading || query.isFetching }
}

export default useFetchEventById
