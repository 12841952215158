import React from 'react'
import styled from 'styled-components'
import { Table } from '../bulma'

interface IDataType {
  name: string,
  value: string
}

interface IProps {
  data: IDataType[]
}

const CellContainer = styled.div`
    width: 100%;
    display: inline-flex;
    span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

function DetailsTable({ data }: IProps) {
  return (
    <Table bordered hoverable size="fullwidth">
      <colgroup>
        <col style={{ width: '25%' }} />
      </colgroup>
      <tbody>
        {
          data.map((value, index) => (
            <tr key={`${index + value.name}`}>
              <td>
                <CellContainer>
                  <strong>
                    {' '}
                    {value.name}
                    {' '}
                  </strong>
                </CellContainer>
              </td>
              <td><CellContainer>{value.value}</CellContainer></td>
            </tr>
          ))
        }

      </tbody>
    </Table>
  )
}

export default DetailsTable
