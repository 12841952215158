import React from 'react'
import { Helmet } from 'react-helmet'
import { TITLES } from '../../constants'
import { EditServerByIdLayout, EditServerByIdForm } from '../../modules/editServerById'

function EditServerById() {
  return (
    <>
      <Helmet>
        <title>{TITLES.editServerById}</title>
      </Helmet>
      <EditServerByIdLayout>
        <EditServerByIdForm />
      </EditServerByIdLayout>
    </>
  )
}

export default EditServerById
