import { useMemo } from 'react'

const useEnvironment = () => {
  const env = useMemo(() => {
    const { hostname } = window.location
    if (process.env.NODE_ENV === 'test') { return 'test' }
    if (hostname === 'portal-dev.dbaas.cloud.disney.com' || hostname === 'localhost') {
      return 'dev'
    } if (hostname === 'portal-qa.dbaas.cloud.disney.com') {
      return 'qa'
    }
    if (hostname === 'portal.dbaas.cloud.disney.com') {
      return 'prod'
    }
    return 'unknown'
  }, [])
  return env
}

export default useEnvironment
