import React from 'react'
import styled from 'styled-components'

const PStyled = styled.p`
  font-size: 0.9rem;
  margin-bottom: 0;
`
const ToolTipBaseContainer = styled.p`
  max-width: 350px;
`
const ToolTipBaseWrapper = styled.p`
  font-size: 0.9rem;
  margin-bottom: 0;
`

function TextTooltip({ children }: { children: React.ReactNode | React.ReactNode[] | string }) {
  return (
    <ToolTipBaseContainer style={{ maxWidth: 350 }}>
      {typeof children === 'string' ? <PStyled className="subtitle">{children}</PStyled> : (
        <ToolTipBaseWrapper className="subtitle">
          {children}
        </ToolTipBaseWrapper>
      )}
    </ToolTipBaseContainer>
  )
}

export default TextTooltip
