import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

const SpinnerContainer = styled.div`
  position: relative;
`
const SpinnerBackground = styled.div<{isFullScreen: boolean}>`
  position: ${({ isFullScreen }) => `${isFullScreen ? 'fixed' : 'absolute'}`};
  top: 0px;
  left: 0px;
  z-index: 4;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  background-color: rgba(7, 3, 3, 0.5);
`
const SpinningContentWrapper = styled.div` 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
`

const SpinningContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContentText = styled.div`
  margin: 2rem;
  h4 {
    color: white
  }
`

function Spinner({
  isLoading, children,
  isFullScreen,
  iconSize,
  tip,
}: {
  isLoading: boolean, children: React.ReactNode | React.ReactNode[],
  iconSize?: SizeProp,
  isFullScreen?: boolean,
  tip?: string
}) {
  return (
    <SpinnerContainer>
      {isLoading && (
      <SpinnerBackground isFullScreen={isFullScreen ?? true} data-testid="loading">
        <SpinningContentWrapper>
          <SpinningContent>
            <FontAwesomeIcon icon={faCog} size={iconSize} spin />
            {typeof tip === 'string' && (
            <ContentText>
              <h4 className="title is-6">
                {tip}
              </h4>
            </ContentText>
            )}
          </SpinningContent>
        </SpinningContentWrapper>
      </SpinnerBackground>
      )}
      {children}
    </SpinnerContainer>
  )
}

Spinner.defaultProps = {
  isFullScreen: true,
  iconSize: '6x',
  tip: undefined,
}

export default Spinner
