import classNames from 'classnames'
import React, { forwardRef } from 'react'
import BaseElements from '../../../elements'
import { IInputElementProps } from '../../../elements/types'

const { Label, Input } = BaseElements

const Checkbox = forwardRef<HTMLInputElement, IInputElementProps>(({
  className,
  style,
  disabled,
  children,
  ...props
}, ref) => (
  <Label
    disabled={disabled}
    className={classNames('checkbox', className)}
    style={style}
  >
    <Input
      type="checkbox"
      disabled={disabled}
      {...props}
      ref={ref}
    />
    {' '}
    {children}
  </Label>
))

export default Checkbox
