import React from 'react'
import { AppSpinner } from '../../components'
import { useFetchIsAdmin } from '../../hooks/api/admin'

function Redirect() {
  const { isLoading } = useFetchIsAdmin()
  return (
    <AppSpinner isLoading={isLoading}>
      <div data-testid="redirect-page" />
    </AppSpinner>
  )
}

export default Redirect
