import classNames from 'classnames'
import React from 'react'
import BaseElements from '../../../elements'
import { IContainerElementProps } from '../../../elements/types'

const { P: Element } = BaseElements

const Help = React.forwardRef<HTMLParagraphElement, Omit<IContainerElementProps, 'renderAs'>>(({
  className, children, color, ...props
}, ref) => (
  <Element
    {...props}
    className={classNames('help', className, {
      [`is-${color}`]: color,
    })}
    ref={ref}
  >
    {children}
  </Element>
))

export default Help
