import InternalColumns from './Columns'
import Column from './components/Column'

type InternalColumnsType = typeof InternalColumns

interface ColumnsType extends InternalColumnsType {
    Column: typeof Column
    constants: {
        SIZES: {
            THREEQUARTERS: 'three-quarters',
            TWOTHIRDS: 'two-thirds',
            HALF: 'half',
            ONETHIRD: 'one-third',
            ONEQUARTER: 'one-quarter',
            ONEFIFTH: 'one-fifth',
            TWOFIFTHS: 'two-fifths',
            THREEFIFTHS: 'three-fifths',
            FOURFIFTHS: 'four-fifths',
        },
    }
}

const Columns = InternalColumns as ColumnsType

Columns.Column = Column

export default Columns
