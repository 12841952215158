import React from 'react'
import classnames from 'classnames'
import { IContainerElementProps } from '../../elements/types'
import BaseElements from '../../elements'

const { Div: Element } = BaseElements

type IProps = Omit<IContainerElementProps, 'renderAs'>

function MessageBody({ children, className, ...props }: IProps) {
  return (
    <Element {...props} className={classnames('message-body', className)}>
      {children}
    </Element>
  )
}

export default MessageBody
