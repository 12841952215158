import { useMutation, useQueryClient } from 'react-query'
import { useDBaaSClient } from '../../client'

const useEditAdministrator = () => {
  const client = useDBaaSClient()
  const queryClient = useQueryClient()

  return useMutation(({ adminId, editAdminName }:{adminId: string, editAdminName:string}) => client.put('/admins', {
    _id: adminId,
    name: editAdminName,
  }), {
    onSuccess: (_, { adminId }) => {
      queryClient.invalidateQueries('admins')
      queryClient.invalidateQueries(['admins', adminId])
    },
  })
}

export default useEditAdministrator
